import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FadeInAnimation } from '../../../shared/animations/fade-in.animation';
import { ConsumptionFormtype } from '../../../shared/formtypes/consumption.formtype';
import { defaultValidatorProxy } from '../../../shared/validators/default-validator-proxy';
import { TRACKING } from '../../enums/trackingParts.enum';
import { VerbrauchstypPipe } from '../../pipes/verbrauchstyp.pipe';
import { StorageService } from '../../services/storage.service';
import { ConsumptionStep, TariffAdvisorService } from '../../services/tariff-advisor.service';
import { TrackingService } from '../../services/tracking.service';
import { CHANGE_TYPE } from '../contract-edit/charge-flex-meter-selection/charge-flex-meter-selection.component';

@Component({
  selector: 'bdo-tariff-consumption-selection',
  templateUrl: './tariff-consumption-selection.component.html',
  styleUrls: ['./tariff-consumption-selection.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class TariffConsumptionSelectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() consumption: number;
  @Input() postCode: string;
  @Input() divisionId: string;
  @Input() isInEditMode: boolean = false;
  @Input() hasMultipleMeters: boolean = false;
  @Input() steps: Array<ConsumptionStep> = [ ];
  @Input() consumptionForm: FormGroup<ConsumptionFormtype> = new FormGroup({});
  @Input() isTrackingDisabled: boolean = false;
  @Output() calculate = new EventEmitter<{consumption: number, chargingConsumption: number}>();
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  public isChargeFlex = StorageService.getChargeFlexContext();
  public chargeFlexHasOwnMeter = StorageService.getChargeFlexData()?.changeType === CHANGE_TYPE.SEPARATE;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private tariffAdvisorService: TariffAdvisorService,
    private trackingService: TrackingService,
    private divisionPipe: VerbrauchstypPipe
  ) { }

  ngOnInit(): void {
    this.consumptionForm?.addControl('selectedStep', new FormControl(''));
    this.consumptionForm?.addControl(
      'consumption',
      new FormControl('', {
        validators: [
          defaultValidatorProxy(Validators.required, this.translateService.instant('tariffAdvisor.error.consumptionRequired')),
          defaultValidatorProxy(Validators.pattern(/^\d*$/), this.translateService.instant('general.error.wrongNumberFormat'))
        ]
      })
    );
    const updateSelectedStepSubscription = this.consumptionForm.controls.selectedStep.valueChanges.subscribe({ next: (step) => {
      if (parseInt(step, 10) > 0) {
        const consumption = this.getConsumptionByStep(step);
        this.consumptionForm.controls.consumption.setValue(consumption?.toString());
      }
    } });
    const consumptionSubscription = this.consumptionForm.controls.consumption.valueChanges.subscribe({ next: (value) => {
      const correspondingConsumptionStep = this.getStepByConsumption(value);
      const isCustomConsumption = !correspondingConsumptionStep;
      if (isCustomConsumption) {
        this.consumptionForm.controls.selectedStep.setValue('0');
      }
      if (!this.isTrackingDisabled) {
        this.trackingService.postBaseAndAdditionalTracking({
          event: 'orca-tarifrechner',
          orca_tarifrechner: 'plz_eingabe',
          orca_plz: this.postCode,
          orca_plz_strasse: '',
          orca_product_sparte: this.divisionPipe.transform(this.divisionId, false),
          orca_jahresverbrauch: value?.toString()
        });
      }
    } });

      this.subscriptions.add(updateSelectedStepSubscription);
      this.subscriptions.add(consumptionSubscription);

    if (!this.consumption) {
      // Prefill with second step
      this.consumptionForm.controls.selectedStep.setValue(this.tariffAdvisorService.getConsumptionSteps(this.divisionId)?.[1]?.step);
    }

    if (!this.consumptionForm.controls.consumption.value &&
      this.isChargeFlex &&
      StorageService.getChargeFlexData().changeType === CHANGE_TYPE.TOGETHER
    ) {
      this.consumptionForm.controls.consumption.setValue((this.consumption - StorageService.getChargeFlexData().chargingConsumption).toString());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.divisionId?.currentValue) {
      this.steps = this.tariffAdvisorService.getConsumptionSteps(changes.divisionId?.currentValue);
      this.consumptionForm.controls.consumption?.setValue(undefined);
      this.consumptionForm.updateValueAndValidity();
    }

    if (changes.consumption?.currentValue) {
      this.consumption = changes.consumption?.currentValue;
      this.consumptionForm.controls.consumption?.setValue(this.consumption.toString());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCalculate() {
    this.consumptionForm.markAllAsTouched();
    this.consumptionForm.updateValueAndValidity();
    this.consumption = parseInt(this.consumptionForm.controls.consumption?.value, 10);
    if (this.isChargeFlex && StorageService.getChargeFlexData().changeType === CHANGE_TYPE.SEPARATE) {
      // Separate Wallbox meter has a home consumption of 0
      this.consumption = this.consumptionForm.controls.chargingConsumption?.value;
      this.consumptionForm.controls.consumption?.setValue(this.consumption.toString());
    } else if (this.isChargeFlex && StorageService.getChargeFlexData().changeType === CHANGE_TYPE.TOGETHER) {
      // With a joint meter we need to add charging to home consumption
      this.consumption += parseInt(this.consumptionForm.controls.chargingConsumption?.value as any, 10);
      this.consumptionForm.controls.consumption?.setValue(this.consumption.toString());
    }

    if (this.consumptionForm.valid) {
      this.calculate.emit({
        consumption: this.consumption,
        chargingConsumption: this.consumptionForm.controls.chargingConsumption?.value
      });
      this.isInEditMode = !this.isInEditMode;
    }
  }

  getConsumptionByStep(step: string) {
    return this.tariffAdvisorService.getConsumptionSteps(this.divisionId)?.find(item => {
      return item.step === step;
    })?.value;
  }

  getStepByConsumption(value: string): string {
    return this.tariffAdvisorService.getConsumptionSteps(this.divisionId)?.find(item => item.value.toString() === value)?.step;
  }

  // Formfield should be a number but pretends to be a string?!?
  getOverallConsumption(): number {
    const consumption = parseInt(this.consumptionForm.controls.consumption.value, 10) || 0;
    const chargingConsumption = parseInt(this.consumptionForm.controls.chargingConsumption?.value as any, 10) || 0;
    return consumption + chargingConsumption;
  }

  onEdit() {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.TARIFF_ADVISOR, TRACKING.FORM_ACTION.SELECT, 'Verbrauchsanpassung');
    this.isInEditMode = !this.isInEditMode;
    this.edit.emit(this.isInEditMode);

    // Substract charging consumption from summed annualConsumption to prefill correctly
    if (this.isChargeFlex && StorageService.getChargeFlexData().changeType === CHANGE_TYPE.TOGETHER
    ) {
      this.consumption -= StorageService.getChargeFlexData().chargingConsumption;
      this.consumptionForm.controls.consumption?.setValue(this.consumption.toString());
    }
  }

}
