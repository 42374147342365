import { Component, computed, effect, Signal, signal, untracked, WritableSignal } from '@angular/core';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { DropdownItem } from '../../../models/dropdownItem';
import { ContractDetails, ContractInformationSummary } from '../../../../../assets/js/com/ts_api_client';
import {
  ChargeFlexService,
  KEY_FOR_PLEASE_SELECT,
  PossibleErrors,
  ResultWithError
} from '../../../services/charge-flex.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CONTEXT_FROM_SUMMARY } from '../../delivery/summary/summary.component';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';
import { Location } from '@angular/common';
import { StorageService } from '../../../services/storage.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';

export enum CHANGE_TYPE {
  SEPARATE = 'SEPARATE',
  TOGETHER = 'TOGETHER'
}

interface MeterSelectType {
  meterNumber: FormControl<string>;
  changeType: FormControl<CHANGE_TYPE>
}

@Component({
  selector: 'bdo-charge-flex',
  templateUrl: './charge-flex-meter-selection.component.html',
  styleUrls: ['./charge-flex-meter-selection.component.scss'],
})

export class ChargeFlexMeterSelectionComponent{

  public CHANGE_TYPE = CHANGE_TYPE;

  public state: Signal<LOADING_STATE> = this.computeState();
  public error: Signal<PossibleErrors> = this.computeError();
  public form = new FormGroup<MeterSelectType>(
    {
      meterNumber: new FormControl(null, Validators.required),
      changeType: new FormControl(CHANGE_TYPE.TOGETHER, Validators.required)
    }
  );
  public selectedTariff: Signal<ResultWithError<ContractDetails>> = this.computeSelectedTariff();
  public selectedMeterNumberFromForm = toSignal(this.form.get('meterNumber')?.valueChanges);
  public meterDropdown: Signal<ResultWithError<DropdownItem[]>> = this.chargeFlexService.getMeterDropdownItems();
  public selectedMeterNumber: Signal<string | undefined> = computed(() => {
    const selectedMeterNumberFromForm = this.selectedMeterNumberFromForm();
    const meterDropdown = this.meterDropdown()?.result;
    if (selectedMeterNumberFromForm) {
      return this.selectedMeterNumberFromForm();
    }
    if (meterDropdown?.length === 1) {
      return meterDropdown[0].value;
    }
  });
  public contractInfos: Signal<ResultWithError<ContractInformationSummary>> = this.chargeFlexService.getContractInformationInfo();
  public readonly LoadingState = LOADING_STATE;

  protected readonly CONTEXT_FROM_SUMMARY = CONTEXT_FROM_SUMMARY;
  protected context: string|undefined;
  protected readonly PossibleErrors = PossibleErrors;

  constructor(
    public location: Location,
    private chargeFlexService: ChargeFlexService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    effect(()  => {
      if (!this.selectedMeterNumber() && this.meterDropdown()?.result.length) {
        untracked(() => {
          const selectedMeterNumberFromStorage = StorageService.getChargeFlexData()?.meterNumber;
          if (selectedMeterNumberFromStorage) {
            this.form.get('meterNumber')?.setValue(selectedMeterNumberFromStorage);
          }
        });
      }
      if (this.meterDropdown()?.result.length === 1) {
        this.form.get('meterNumber')?.setValue(this.meterDropdown()?.result[0].value);
      }
    });
    // check, if this is a jump back from the summary page
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
  }

  public save() {
    StorageService.updateChargeFlexData({
      meterNumber: this.form.get('meterNumber')?.value,
      changeType: this.form.get('changeType')?.value
    });
    StorageService.setOldContract(this.selectedTariff().result);
    this.router.navigate(['../tarifauswahl'], { relativeTo: this.activatedRoute });
  }

  private computeState(): Signal<LOADING_STATE> {
    return computed(() => {
      if (this.meterDropdown() === undefined || this.contractInfos() === undefined) {
        return LOADING_STATE.LOADING;
      }
      if (this.meterDropdown()?.error || this.contractInfos()?.error || this.selectedTariff()?.error) {
        return LOADING_STATE.ERROR;
      }
      else {
        return LOADING_STATE.IDLE;
      }
    });
  }

  private computeError(): Signal<PossibleErrors> {
    return computed(() => {
      if (this.state() === LOADING_STATE.ERROR) {
        return this.meterDropdown().error?.details || this.contractInfos().error?.details || this.selectedTariff().error?.details;
      }
      return undefined;
    });
  }

  private computeSelectedTariff(): Signal<ResultWithError<ContractDetails>> {
    return computed(() => {
      const selectedMeterNumber = this.selectedMeterNumber();
      const contractInfos = this.contractInfos()?.result;
      if (selectedMeterNumber && selectedMeterNumber !== KEY_FOR_PLEASE_SELECT && contractInfos){
        const foundContract = contractInfos.contractDetails.find(contract => contract.meterNumbers.includes(selectedMeterNumber));
        return foundContract ? this.chargeFlexService.ok<ContractDetails>(foundContract) : this.chargeFlexService.error(PossibleErrors.NO_MATCHING_CONTRACT);
      }
    });
  }

}
