<div class="re-font-regular re-text-xs re-leading-tight re-text-white re-text-center re-pt-15 re-pb-15 re-px-20 re-whitespace-nowrap re-rounded-t-lg md:re-pt-20"
     [ngClass]="tariffData?.badge?.type && tariffData?.badge?.text ? tariffData?.badge?.type : ''" *ngIf="tariffData?.badge?.text && tariffData?.badge?.type">
  <span>{{ tariffData?.badge?.text}}</span>
</div>

<div class="re-p-2 re-flex re-flex-grow" [ngClass]="tariffData?.badge?.type && tariffData?.badge?.text ? tariffData?.badge?.type : 'lg:re-mt-55'">
  <div class=" re-relative re-bg-white re-px-20 re-pt-30 re-pb-20 re-flex re-flex-col re-flex-grow md:re-pt-45" [ngClass]="isSmall ? 'lg:re-px-20' : 'lg:re-px-30'">
    <div class="abstract-tile-bonus re-absolute re-bg-primary re-font-regular re-text-white re-text-xs re-leading-xs re-px-20 re-py-10" *ngIf="hasBonus">
      {{ (tariffData.parts[0].contractPayments[0].baseGross | number:'1.0-0' | cutMinus) + ' € Bonus'}}
    </div>
    <div class="tile-top re-flex-grow">
      <div *ngIf="iconSrc" class="tile-top-icon re-hidden re-w-75 re-h-75 md:re-block md:re-mb-30 lg:re-mb-20 xl:re-mb-15 re-mx-auto">
        <bdo-svg-cms [iconSrc]="iconSrc" [iconSize]="75"></bdo-svg-cms>
      </div>

      <div *ngIf="iconSrc" class="tile-top-icon re-w-60 re-h-60 re-block md:re-hidden re-mb-10 re-mx-auto">
        <bdo-svg-cms [iconSrc]="iconSrc" [iconSize]="60"></bdo-svg-cms>
      </div>

      <div class="re-mb-15 md:re-mb-20 re-text-center">
        <span class="re-text-sm bold re-block re-leading-m" [innerHTML]="tariffData?.namePrefix"></span>
        <h2 class="h4" [ngClass]="{'tile-headline' : isSmall}" [innerHTML]="tariffData?.name" data-qa='tariff-headline'></h2>
      </div>
      <div class="price-total-month re-text-center re-leading-none">
        <div class="re-mb-5" [ngClass]="{'tile-price' : isSmall}">
          <div class="re-flex re-justify-center re-flex-col">
            <div class="re-flex re-justify-center re-flex-row">
              <h2 class="re-text-l md:re-text-xl">{{ (tariffData?.parts?.[0]?.priceCap ?
                        prices[0].priceCapYear.monthlyPrice : prices[0].firstYear.monthlyPrice) | number:'1.2-2' }} €</h2>
              <span>
                <bdo-inline-tooltip class="re-flex re-ml-10" *ngIf="infotext">
                  <span [innerHTML]="infotext"></span>
                </bdo-inline-tooltip>
              </span>
            </div>
          </div>
        </div>
        <span class="re-leading-normal re-text-xs md:re-text-sm lg:re-text-xs 2xl:re-text-sm">
          {{ 'tariff.perMonth' | translate }}
        </span>
      </div>

      <div class="re-relative re-mt-15 re-h-20 re-content-box md:re-mt-20" data-qa="marker">
        <hr class="re-absolute re-w-full re-m-0 re-top-1/2 re-transform re--translate-y-1/2 re-border-monochrome-medium-light re-h-px">
        <div data-qa="marker-text" class="re-absolute re-bg-white re-top-1/2 re-left-1/2 re-transform re--translate-y-1/2 re--translate-x-1/2 re-text-2xs re-leading-xs re-py-7 re-px-15 re-border re-border-solid re-border-monochrome-medium-light re-whitespace-nowrap re-font-regular re-rounded-full re-text-xxs"
          *ngIf="tariffData?.markerText && tariffData?.parts[0]?.contractPayments?.length > 0">
          {{ 'tariff.tile.markerText' | translate }}
        </div>
      </div>

      <!-- show upcoming price adjustment -->
      <div *ngIf="prices.length > 1">
        <div class="re-flex re-items-center re-justify-center re-mt-5 re-mb-10 re-fill-current">
          <svg-icon svgClass="re-block re-w-20 re-h-20 re-mr-10 re-text-chart-bar-3" src="assets/icons/system_icons/alert.svg"></svg-icon>
          <div class="re-text-xs bold">{{ 'tariff.priceAdjustment.hintSmall' | translate }}</div>
        </div>
        <hr class="re-w-full re-m-0 re-top-1/2 re-transform re--translate-y-1/2 re-border-monochrome-medium-light re-h-px">
      </div>
      <ng-container *ngIf="getChargeFlexBonusData() as bonusData">
        <div>
          <bdo-charge-flex-bonus [bonusValue]="bonusData.bonusValue" [bonusText]="bonusData.bonusText" />
          <hr class="re-w-full re-m-0 re-top-1/2 re-transform re--translate-y-1/2 re-border-monochrome-medium-light re-h-px">
        </div>
      </ng-container>

      <ul class="bdo-list__bullet re-text-sm re-pt-15 re-mt-5 md:re-mt-10 lg:re-pt-15 2xl:re-pt-20 re-block re-list-none re-flex-grow-1">
        <li *ngFor="let benefit of tariffData?.benefits" class="re-mb-5"><span>{{ benefit }}</span></li>
      </ul>
    </div>

    <div class="tile-bottom re-text-center re-flex-grow-0 re-mt-10">
      <div class="re-mb-10 2xl:re-mb-15">
        <button class="re-button re-relative re-primary re-border-none re-w-full re-primary" (click)="onSelect()" data-qa="tariff-select-button">
          <span>{{ 'tariff.tile.select' | translate }}</span>
        </button>
      </div>
      <div class="re-leading-none">
        <button class="re-text-small re-w-auto re-inline-block re-border-b bdo-link--inline" (click)="showTariffDetails = true">
          {{ 'tariff.tile.showDetails' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>


<bdo-tariff-overlay
  *ngIf="tariffData"
  [prices]="prices"
  [tariffData]="tariffData"
  [isVisible]="showTariffDetails"
  (close)="closeTariffDetails()"
></bdo-tariff-overlay>
