import { Component, input } from '@angular/core';

@Component({
  selector: 'bdo-charge-flex-bonus',
  templateUrl: './charge-flex-bonus.component.html'
})
export class ChargeFlexBonusComponent {
  public bonusValue = input.required<number>();
  public bonusText = input.required<string>();
}
