import { inject, Injectable, Injector, Signal } from '@angular/core';
import { BdoApiService } from './bdo-api.service';
import { VerbrauchstypPipe } from '../pipes/verbrauchstyp.pipe';
import { CustomerStoreService } from './customer-store.service';
import { DropdownItem } from '../models/dropdownItem';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ContractInformationSummary, ZaehlerIdentifier } from '../../../assets/js/com/ts_api_client';
import { TranslateService } from '@ngx-translate/core';

export enum PossibleErrors {
  ERROR_FETCHING_METERNUMBERS = 'No meter selected',
  ERROR_FETCHING_CONTRACT_INFOS = 'No contract found',
  NO_MATCHING_CONTRACT = 'No matching contract found',
}

export interface ResultWithError<T> {
  result: T | null;
  error: {
    message?: string;
    details: PossibleErrors;
  } | null;
}

export const KEY_FOR_PLEASE_SELECT = 'please select';

@Injectable({
  providedIn: 'root'
})
export class ChargeFlexService {
  private injector = inject(Injector);

  constructor(
    private apiService: BdoApiService,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private customerStoreService: CustomerStoreService,
    private translateService: TranslateService
  ) {}

  public getMeterDropdownItems(): Signal<ResultWithError<DropdownItem[]>> {
    return toSignal(
      this.customerStoreService.currentAccountId$.pipe(
        filter(accountId => !!accountId),
        switchMap(() => {
          return this.apiService.getZaehlerIdentifiers(false).pipe(
            this.getAsOptionsS4Only()
          );
        }),
        catchError((error) => of(
          this.error(PossibleErrors.ERROR_FETCHING_METERNUMBERS, error.message
          )))
      ),
      { initialValue: undefined, injector: this.injector }
    );
  }

  public getContractInformationInfo(): Signal<ResultWithError<ContractInformationSummary>> {
    return toSignal(
      this.customerStoreService.currentAccountId$.pipe(
        filter(accountId => !!accountId),
        switchMap((accountId) => {
          return this.apiService.getContracts(accountId);
        }),
        map((result) => {
          return this.ok<ContractInformationSummary>(result);
        }),
        catchError((error) => of(this.error(PossibleErrors.ERROR_FETCHING_CONTRACT_INFOS, error.message)))
      ),
      { initialValue: undefined, injector: this.injector }
    );
  }

  public error(possibleErrors: PossibleErrors, message?: string): ResultWithError<null> {
    return {
      result: null,
      error: {
        message: message,
        details: possibleErrors
      }
    };
  }

  public ok<T>(state: T): ResultWithError<T> {
    return {
      result: state,
      error: null
    };
  }

  private getAsOptionsS4Only() {
    return map((res: ZaehlerIdentifier[]) => {
        const options = res
          .filter(val => !val.inaktiv)
          .filter(val => (val.sparte === 'S4'))
          .map(val => ({
            label: `${this.verbrauchstypPipe.transform(val.sparte)}-Nr. ${val.meterNumber}`,
            value: val.meterNumber
          }));
        if (options.length > 1) {
          options.unshift( { label: this.translateService.instant('tmh.selectMeternumber.pleaseSelect'), value: KEY_FOR_PLEASE_SELECT });
        }
        return this.ok([
          ...options
        ]);
      }
    );
  }

}
