import { Component, OnInit } from '@angular/core';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ContractDetails, PartnerEmail } from '../../../../../assets/js/com/ts_api_client';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { Observable } from 'rxjs';
import { TariffSelection } from '../../../../shared/models/tariff-selection';
import { Utilities } from '../../../../shared/utils/utilities';
import { LocationStrategy } from '@angular/common';
import { TrustpilotService } from '../../../services/trustpilot.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';

@Component({
  selector: 'bdo-contract-edit-confirmation',
  templateUrl: './contract-edit-confirmation.component.html',
  styleUrls: ['./contract-edit-confirmation.component.scss']
})
export class ContractEditConfirmationComponent implements OnInit {

  public oldContract: ContractDetails;
  public tariff: TariffSelection;
  public emailAddress$: Observable<PartnerEmail>;
  public postCode: string;
  public TRACKING = TRACKING;
  public trustpilotUniqueUrl: string;
  public wishvoucherAmount: string;
  public isChargeFlex = StorageService.getChargeFlexContext();

  constructor(
    public bdoApiService: BdoApiService,
    private trackingService: TrackingService,
    private locationStrategy: LocationStrategy,
    private trustpilotService: TrustpilotService,
    public tariffService: TariffAdvisorService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CHANGE_DONE, TRACKING.ACTION.ENTER);
    this.oldContract = StorageService.getOldContract();
    this.tariff = StorageService.getFirstTariffSelection();
    this.postCode = StorageService.getPersonalData()?.addressData?.postCode;
    this.trustpilotUniqueUrl = this.trustpilotService.buildTrustpilotUniqueUrl(StorageService.getTrustPilotToken());
    const authData: AuthDataStorage = StorageService.getAuthData();
    const vouchers = StorageService.getOfferVouchers();
    // Currently there is only one voucher possible
    this.wishvoucherAmount = vouchers?.list?.[0]?.value;

    this.emailAddress$ = this.bdoApiService.getEmailNormalized(authData);

    Utilities.disableBrowsersBackButton(this.locationStrategy);
    window.sessionStorage.clear();
  }

}
