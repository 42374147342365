import { Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { remove as _remove, uniq as _uniq } from 'lodash';
import { map, Observable, of, shareReplay, Subscription, tap } from 'rxjs';
import { ConsumptionFormtype } from '../../../../../shared/formtypes/consumption.formtype';
import { Utilities } from '../../../../../shared/utils/utilities';
import { defaultValidatorProxy } from '../../../../../shared/validators/default-validator-proxy';
import { INPUT_TYPE } from '../../../../enums/inputType.enum';
import { TRACKING } from '../../../../enums/trackingParts.enum';
import { TmhData } from '../../../../models/tmhData';
import { BdoApiService } from '../../../../services/bdo-api.service';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'bdo-ecar-selection',
  templateUrl: './ecar-selection.component.html',
  styleUrl: './ecar-selection.component.scss'
})
export class EcarSelectionComponent implements OnInit, OnDestroy {
  @Input() parentForm = new FormGroup<ConsumptionFormtype>({});

  public tmhData: Array<TmhData>;
  public manufacturers$: Observable<string[]> = of([]);
  public manufacturerSearchString$: Observable<string>;
  public carmodels$: Observable<string[]>;
  public carmodelSearchString$: Observable<string>;
  public sortManufacturer = Utilities.sortAutosuggest;
  public INPUT_TYPE = INPUT_TYPE;
  public NOTFOUND: string =  'Modell nicht dabei'; // Filter this out so we can add it as extra option
  public compatibility: WritableSignal<undefined | string> = signal(undefined);
  public TRACKING = TRACKING;
  private subscriptions = new Subscription();

  constructor(
    private apiService: BdoApiService,
    private translateService: TranslateService
  ){}

  ngOnInit(): void {
    this.parentForm.addControl(
      'manufacturer',
      new FormControl<string>('',
        [
          defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          this.manufacturerExistsValidator()
        ]),
    );
    this.parentForm.addControl(
      'carmodel',
      new FormControl<string>('',
        [
          defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          this.carmodelExistsValidator()
        ])
    );
    this.manufacturerSearchString$ = this.parentForm.controls.manufacturer?.valueChanges;
    this.carmodelSearchString$ = this.parentForm.controls.carmodel?.valueChanges;
    this.subscriptions.add(this.manufacturers$.subscribe()); // intial load of manufacturers

    this.manufacturers$ = this.apiService.getTmhData().pipe(
      tap((data) => {
        this.tmhData = data;
      }),
      map((data: Array<TmhData>) => {
        // Remove duplicates, extract manufacturers and order alphabetically
        return _uniq(
            data.map(item => item.manufacturer)
          )?.
          sort((a,b) =>  (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
      }),
      tap(() => {
        this.parentForm.controls.manufacturer.setValue(StorageService.getChargeFlexData()?.manufacturer || '');
        this.parentForm.controls.carmodel.setValue(StorageService.getChargeFlexData()?.carmodel || '');
        this.compatibility.set(this.getCompatibilityInfo(this.parentForm.controls.manufacturer.value, this.parentForm.controls.carmodel.value));
      }),
      shareReplay(1)
    );



  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onManufacturerSelected(selectedManufacturer: string) {
    this.parentForm.controls.manufacturer.setValue(selectedManufacturer);
    this.updateCarModels(selectedManufacturer);
    this.parentForm.controls.carmodel.setValue('');
    this.compatibility.set(this.getCompatibilityInfo(selectedManufacturer, this.parentForm.controls.carmodel.value));
  }

  public updateCarModels(selectedManufacturer: string) {
    const models = this.tmhData.filter(item => item.manufacturer === selectedManufacturer).
      map(item => item.model).
      filter(item => item !== this.NOTFOUND).
      sort((a,b) =>  (a > b ? 1 : -1));
    this.carmodels$ = of(
      models
    );
    this.parentForm.controls.carmodel.updateValueAndValidity();
  }

  public onModelSelected(selectedModel: string) {
    this.parentForm.controls.carmodel.setValue(selectedModel);
    this.compatibility.set(this.getCompatibilityInfo(this.parentForm.controls.manufacturer.value, selectedModel));
    StorageService.updateChargeFlexData({
      compatibility: this.compatibility()?.length > 0
    });
  }

  public getCompatibilityInfo(selectedManufacturer: string, selectedModel: string) {
    const selectedTmhItem = this.tmhData?.find(item => item.manufacturer === selectedManufacturer && item.model === selectedModel);

    return selectedTmhItem?.constructionYearNote_de;
  }

    /**
   * Additional Validator to check for existing manufacturers if typed in manually
   */
  private manufacturerExistsValidator() {
    return (control: AbstractControl): { [key: string]: string | true } | null => {
      if (!control.value) {
        return null;
      }

      const errorText = this.translateService.instant('tmh.consumption.validator.manufacturer');

      if (!this.tmhData.some(item => item.manufacturer === control.value)) {
        return { ['manufacturerNotExists']: (errorText) };
      }
      return null;
    };
  }
    /**
   * Additional Validator to check for existing manufacturers if typed in manually
   */
  private carmodelExistsValidator() {
    return (control: AbstractControl): { [key: string]: string | true } | null => {
      if (!control.value) {
        return null;
      }
      const errorText = this.translateService.instant('tmh.consumption.validator.carmodel');

      if (!this.tmhData?.some(item => item.manufacturer === this.parentForm.controls.manufacturer.value && item.model === control.value)) {
        return { ['carmodelNotExists']: (errorText) };
      }
      return null;
    };
  }
}
