import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { AddressData, ContractDetails, ContractInformationSummary } from '../../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import moment from 'moment';
import { StorageService } from '../../../services/storage.service';
import { LoginService } from '../../../../login/login.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { CONTEXT_FROM_SUMMARY } from '../../contract-new/contract-new-summary/contract-new-summary.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { KeyValue, Location } from '@angular/common';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';
import { PriceCapService } from '../../../services/price-cap.service';
import { CONTRACT_DETAILS_TILE_MODE } from '../../../enums/contract-details-tile-mode.enum';
import { Environment } from '../../../../../environments/environment';
import { TENANT } from '../../../enums/tenant.enum';
import { STAGE } from '../../../enums/stage.enum';

const HIDDEN_ON_CANCEL = [VERBRAUCHSTYP.Wasser.toString(), VERBRAUCHSTYP.Fernwärme.toString(), VERBRAUCHSTYP.Nahwärme.toString()];

interface GroupedContractsByDivisionAndMeternumber extends Record<string, Record<string, ContractDetails[]>>{}

export interface ContractHint {
  division: string;
  isBaseSupply: boolean;
}

@Component({
  selector: 'bdo-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss']
})
export class ContractDetailsComponent implements OnChanges {
  @Input() accountId: string = '';
  @Input() meterNumber: string = '';
  @Input() cancelMode: boolean = false;
  @Input() address: AddressData;
  @Input() trackingLocation: string;
  @Input() trackingDestination: string;
  public LoadingState = LOADING_STATE;
  public ContractDetailsTileMode = CONTRACT_DETAILS_TILE_MODE;
  public state = LOADING_STATE.IDLE;
  public Verbrauchstyp = VERBRAUCHSTYP;
  public groupedContracts: GroupedContractsByDivisionAndMeternumber;
  public today = moment().startOf('day').toDate();
  public Object = Object;
  public additionalServices: ContractDetails;
  public customerMode: CUSTOMERMODE = CUSTOMERMODE.GEWE;
  public CUSTOMERMODE = CUSTOMERMODE;
  public isRE = Environment.tenant === TENANT.Rheinenergie;
  public TRACKING = TRACKING;
  private contractHints: Array<ContractHint> = [];
  private context: string;

  constructor(
    public tariffAdvisorService: TariffAdvisorService,
    public priceCapService: PriceCapService,
    private apiService: BdoApiService,
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
    private location: Location
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.groupedContracts = undefined;
    this.additionalServices = undefined;
    this.state = LOADING_STATE.LOADING;

    if (!this.cancelMode || (this.accountId && this.meterNumber)) {
      this.loginService.isLoggedIn$.pipe(
        first(),
        switchMap((loggedIn) => {
          return loggedIn ? this.apiService.getContracts(this.accountId) : this.apiService.getContractsAnonymous(this.accountId, this.meterNumber);
        })
      ).subscribe(
        {
          next: (res: ContractInformationSummary) => {
            const contractsWithoutZUAA = this.getContractsWithoutZUAA(res.contractDetails);
            this.additionalServices = res.contractDetails.filter(contract => contract.contractId === 'ZUAA')?.[0];
            const contractsGroupedByDivision = _.groupBy(contractsWithoutZUAA, contract => contract.division);
            this.groupedContracts = this.getContractsGroupedByDivisionAndMeterNumbers(contractsGroupedByDivision);

            if (this.address) {
              this.initHintsForAllContracts(this.groupedContracts);
            }
            if (res?.contractDetails[0]?.customerType) {
              this.customerMode = res.contractDetails[0].customerType as CUSTOMERMODE;
            } else if (this.groupedContracts && Object.keys(this.groupedContracts).length > 0){
              if (!this.meterNumber) {
                this.meterNumber = this.getFirstMeterNumberOfGroupedContracts(this.groupedContracts);
              }
              this.apiService.getCustomerModeAnonymous(this.accountId, this.meterNumber).pipe(
              ).subscribe({ next: (customerModeResponse) => {
                this.customerMode = customerModeResponse.info as CUSTOMERMODE;
              } });
            }

            this.state = LOADING_STATE.IDLE;
        }, error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
    }
  }



  initHintsForAllContracts(groupedContracts: Dictionary<Dictionary<ContractDetails[]>>) {
    Object.keys(groupedContracts).forEach((division) => {
      this.apiService.getIsBaseSupply(this.address, division)
        .subscribe({ next: isBaseSupplyArea => {
          const isBaseSupply: boolean = isBaseSupplyArea?.info?.toLowerCase() === 'true';
          this.contractHints.push({
            division: division,
            isBaseSupply: isBaseSupply
          });
      } });
    });
  }

  getContractHint(division: string): ContractHint {
    return this.contractHints.find(contractHint => contractHint.division === division);
  }

  getContractNsh(contractId: string): boolean {
    let isNsh = false;
    if (Environment.tenant !== TENANT.StadtwerkeLeichlingen) {
      Object.values(this.groupedContracts).forEach(groupContractDivision => {
        Object.values(groupContractDivision).forEach(groupContractMeter => {
          Object.values(groupContractMeter).forEach(contract => {
            if (contract.contractId === contractId && (contract.tariffName.includes('NSH 1') || contract.tariffName.includes('NSH 2'))) {
              isNsh = true;
            }
          });
        });
      });
    }
    return isNsh;
  }

  onCancelContract(contract: ContractDetails) {
    const isSameContract: boolean = StorageService.getOldContract()?.contractId == contract?.contractId;
    this.storeSelectedContract(contract);

    if (this.context === CONTEXT_FROM_SUMMARY && isSameContract) {
      this.trackingService.postSimpleTracking(this.trackingLocation, TRACKING.ACTION.GOTO, 'Übersicht');
      this.router.navigate(['../uebersicht'], {
        relativeTo: this.activatedRoute
      });
    } else {
      this.trackingService.postSimpleTracking(this.trackingLocation, TRACKING.ACTION.GOTO, 'Vertragsende');
      this.router.navigate(['../vertragsende'], {
        relativeTo: this.activatedRoute,
        ...(this.context === CONTEXT_FROM_SUMMARY && { // only add state property if context is set
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        })

      });
    }
  }
  onChangeTariff(contract: ContractDetails) {
    this.storeSelectedContract(contract);
    if (this.cancelMode) {
      this.trackingService.postSimpleTracking(this.trackingLocation, TRACKING.ACTION.GOTO, 'Tarif ändern');
    }
  }

  storeSelectedContract(contract: ContractDetails) {
    StorageService.setOldContract(contract);
  }

  getChangeReason(contract: ContractDetails, currentGroupContracts: Array<ContractDetails>): 'priceChanges' | 'productChanges' {
    return contract.productId === currentGroupContracts[0].productId ? 'priceChanges' : 'productChanges';
  }

  // check if one of the contracts is affected by the price cap
  showHintPriceCap(contractsGroupedByDivision: Dictionary<ContractDetails[]>): boolean {
    let hasPriceCap: boolean = false;
    Object.values(contractsGroupedByDivision).forEach((contracts: ContractDetails[]) => {
      if (contracts.filter(contract => this.priceCapService.getContractAffectedByPriceCap(contract, this.customerMode)).length > 0) {
        hasPriceCap = true;
      }
    });

    return hasPriceCap;
  }

  getTotalAmountOfDivision(contractsOfDivision: KeyValue<string, Dictionary<ContractDetails[]>>): number {
    let amount: number = 0;
    Object.values(contractsOfDivision.value).forEach((contracts: Dictionary<ContractDetails[]>[]) => {
      if (contracts[0].billingAmount) {
        amount += contracts[0].billingAmount as any as number;
      } else {
        amount += (contracts[0].customerType as any as string === CUSTOMERMODE.PRIV ? contracts[0].monthlyPriceGross : contracts[0].monthlyPriceNet) as any as number;
      }
    });
    return amount;
  }

  trackIt() {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_OVERVIEW, TRACKING.ACTION.GOTO, TRACKING.LOCATION.WEBSITE);
  }

  setChargeFlexContext() {
    StorageService.setChargeFlexContext();
    this.router.navigate(['./anpassen/zaehlerauswahl'], {
      relativeTo: this.activatedRoute
    });
  }

  private getFirstMeterNumberOfGroupedContracts(groupedContracts: GroupedContractsByDivisionAndMeternumber) {
    const firstContractGroupedByMeterNumber = Object.keys(groupedContracts)[0];
    return Object.keys(groupedContracts[firstContractGroupedByMeterNumber])[0];
  }

  private getContractsGroupedByDivisionAndMeterNumbers(contractsGroupedByDivision: Dictionary<ContractDetails[]>): GroupedContractsByDivisionAndMeternumber {
    return Object.entries(contractsGroupedByDivision).reduce((result, [division, contractDetails]) => {
      result[division] = _.groupBy(contractDetails, (contractDetail) => {
        return contractDetail.meterNumbers.sort().join(' ');
      });
      return result;
    }, {} as GroupedContractsByDivisionAndMeternumber);
  }

  private getContractsWithoutZUAA(contractDetails: Array<ContractDetails>) {
    const contractsWithoutZUAA = contractDetails
      .filter(contract => contract.contractId !== 'ZUAA' && contract.meterNumbers.length > 0)
      .filter(contract => this.cancelMode ? !HIDDEN_ON_CANCEL.includes(contract.division) : true)
      .filter(contract => this.cancelMode ? !moment(contract.contractStartDate).isAfter(moment().endOf('day')) : true) // Hide future contracts for cancellation
    ;
    return contractsWithoutZUAA;
  }
}
