<div class="re-container-headline re-mb-30 re-mt-20 re-block
          md:re-mb-45 md:re-mt-35
          lg:re-mb-60">
  <h3>{{'tmh.selectMeternumber.title' | translate}}</h3>
</div>
<div class="bdo-container--single re-mb-25">
  <div class="re-copytext re-mb-15 md:re-mb-30">
    {{ 'tmh.selectMeternumber.description' | translate }}
  </div>
  <bdo-info-box class="bdo-container--single" data-qa="error" *ngIf="state() === LoadingState.ERROR && error() !== PossibleErrors.NO_MATCHING_CONTRACT; else content">
    {{ 'general.error.error' | translate }}
  </bdo-info-box>

  <ng-template #content>
    <form
      bdoScrollToInvalidControl
      class="re-relative"
      [class.bdo-loading__wrapper--height]="state() === LoadingState.LOADING"
      [formGroup]="form"
      [bdoScrollToInvalidControlForm]="form"
      (ngSubmit)="save()"
      (keydown.enter)="$event.preventDefault()">
        <bdo-loading *ngIf="state() === LoadingState.LOADING"></bdo-loading>
        <div *ngIf="meterDropdown()?.result?.length > 0" class="re-bg-white re-p-30 md:re-pb-45 re-mb-20" [class.re-min-h-[220px]]="meterDropdown().result.length > 1">

        <div [ngClass]="{ 're-container__content': meterDropdown().result?.length > 1 }" *ngIf="state() !== LoadingState.LOADING">
          <bdo-dropdown
            [data]="meterDropdown().result"
            [formControl]="form.controls.meterNumber"
            [labelText]="'general.customerData.meternumber' | translate"
            [selected]="selectedMeterNumber()"
          >
          </bdo-dropdown>
          <div *ngIf="error() === PossibleErrors.NO_MATCHING_CONTRACT" data-qa="error-no-matching-contract">
            {{ 'tmh.selectMeternumber.error.no-matching-contract' | translate }}
          </div>
          <div class="re-mt-25" data-qa="tariff-name">
            <div *ngIf="selectedTariff()?.result?.tariffName">
              <span>{{ 'tmh.selectMeternumber.currentTariff' | translate }}</span> <span
              class="bold">{{ selectedTariff()?.result.tariffName }}</span></div>
          </div>
        </div>
      </div>

      <div class="re-bg-white re-pl-60 re-pr-60 re-pt-30 re-pb-45" data-qa="selectType" *ngIf="selectedTariff()?.result?.tariffName">
        <h3 class="re-text-sm re-mt-0 re-mb-30 re-pt-0">{{ 'tmh.selectMeternumber.question' | translate }}</h3>
        <div class="re-flex re-items-start re-relative re-mb-20">
          <input id="together" type="radio" [value]="CHANGE_TYPE.TOGETHER" name="changeType" [formControl]="form.controls.changeType">
          <label class="re-ml-15" for="together">{{ 'tmh.selectMeternumber.sameMeter' | translate }}</label>
        </div>
        <div class="re-flex re-items-start re-relative">
          <input id="separate" type="radio" [value]="CHANGE_TYPE.SEPARATE" name="changeType" [formControl]="form.controls.changeType">
          <label class="re-ml-15" for="separate">{{ 'tmh.selectMeternumber.differentMeter' | translate }}</label>
        </div>
      </div>

      <div class="re-w-full re-mt-30
              md:re-w-600 md:re-mx-auto md:re-mt-45
              lg:re-w-900">
        <div class="md:re-pl-0 re-pl-15">
          <bdo-validate-submit
            [isContextSummary]="context === CONTEXT_FROM_SUMMARY"
            [textButtonLong]="'wizard.button.flexConsumption' | translate"
          ></bdo-validate-submit>
          <div class="re-pt-15">
            <bdo-text-icon
              [iconPosition]="'left'"
              [text]="'general.action.back' | translate"
              (itemClicked)="location.back()"
            ></bdo-text-icon>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</div>

