import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContractDetails } from '../../../../../../assets/js/com/ts_api_client';
import { TariffSelection } from '../../../../../shared/models/tariff-selection';
import moment from 'moment';
import { ChargeFlexData } from '../../../../models/chargeFlexData';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'bdo-contract-details-summary',
  templateUrl: './contract-details-summary.component.html',
  styleUrls: ['./contract-details-summary.component.scss']
})
export class ContractDetailsSummaryComponent implements OnChanges {

  @Input() oldContract: ContractDetails;
  @Input() newTariff: TariffSelection;

  public calculatedContractEndDate: Date;
  public chargeFlexData: ChargeFlexData = StorageService.getChargeFlexData();

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.newTariff) {
      // Contract start date is always one day before the old contract ends.
      const dayBeforeContractStartDate = moment(this.newTariff.contractStartDate).subtract(1, 'days').toDate();
      this.calculatedContractEndDate = dayBeforeContractStartDate;
    }
  }
}
