<ng-container *ngIf="data?.length > 1; else single">
  <ng-container class="error" *ngIf="(required && ((form.controls.selected?.touched && form.controls.selected?.invalid) || forceValidate && form.controls.selected?.invalid)); then error else label"></ng-container>

  <ng-template #error>
    <div class="error">{{requiredErrorText}}</div>
  </ng-template>
  <ng-template #label>
    <div class="re-block re-mb-3" [class.re-invisible]="!form.controls.selected?.value">{{ labelText }}</div>
  </ng-template>
  <div class="re-relative">
    <div class="select-container">
      <select
        [formControl]="form.controls.selected"
        [class.re-font-regular]="selected?.length > 0"
        [required]="required ? 'required' : null"
        [attr.data-qa]="dataQa"
        [attr.aria-label]="ariaLabel || labelText"
        (change)="selectItem($event)"
        >
        <option hidden *ngIf="required" value="">{{labelText}}</option>
        <option *ngFor="let item of data" [value]="item.value" [selected]="selected === item.value" class="re-font-regular">{{item.label}}</option>
      </select>
      <bdo-svg name="icon-edge-arrow-down" styling="iconPosition-down"></bdo-svg>
    </div>
    <div *ngIf="required && (form.controls.selected.touched || forceValidate)" class="validation-icon">
      <bdo-svg [name]="form.controls.selected.valid? 'icon-success' : 'icon-alert'" [styling]="form.controls.selected.valid? 'bdo-svg--success' : 'bdo-svg--error'"></bdo-svg>
    </div>
  </div>
</ng-container>

<ng-template #single>
  <div data-qa="single-dropdown-value">{{ labelText }} <span class="bold">{{data?.[0]?.label}}</span></div>
  <input type="hidden" [formControl]="form.controls.selected" [value]="data?.[0]?.value">
</ng-template>
