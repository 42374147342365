<div class="re-container-headline bdo-space--bottom">
	<h3>
		{{ 'confirmation.order.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">

  <bdo-info-box [type]="'warning'" *ngIf="registrationError">
    {{ 'confirmation.error.registration' | translate }}
  </bdo-info-box>
  <div class="re-copytext-with-margin">
    {{ 'confirmation.order.description' | translate }}
  </div>
  <h2 class="re-mb-15 lg:re-mb-30">{{ 'confirmation.title' | translate }}</h2>
  <ul class="bdo-list__bullet_bigger re-text-sm re-leading-m re-mb-45 lg:re-text-base lg:re-leading-2xl lg:re-mb-60">
    <li class="re-mb-5"
        [innerHTML]="'confirmation.order.acknowledgment' | translate: { emailAddress: personalData?.email }">
    </li>
    <li *ngIf="situationData?.previousSupplier" class="re-mb-5"
        [innerHTML]="'confirmation.order.cancelPreviousSupplier' | translate: { previousSupplier: situationData?.previousSupplier }">
    </li>
    <li data-qa="documents"
        [innerHTML]="'confirmation.order.documents' | translate"
        class="re-mb-5">
    </li>
    <li class="re-mb-5" *ngIf="situationData?.startDate"
        [innerHTML]="'confirmation.order.supplyBegins' | translate: { startDate: situationData?.startDate | date }">
    </li>
    <li *ngIf="personalData?.registrationData?.username"
        [innerHTML]="'confirmation.order.registrationLink' | translate">
    </li>
    <li *ngIf="wishvoucherAmount"
        class="re-mt-5"
        [innerHTML]="'confirmation.order.wishvoucher' | translate: { amount: wishvoucherAmount | currency: 'EUR' }">
    </li>
    <ng-container *ngIf="tariffService.isEffzeh([tariffData.productId])">
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehVoucher' | translate}}</li>
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehPayout' | translate}}</li>
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehCompetition' | translate}}</li>
    </ng-container>
    <li *ngIf="isChargeFlex" class="re-mt-5 md:re-mt-10"
      [innerHTML]="
      'confirmation.order.chargeFlex' | translate"
    ></li>
  </ul>
</div>

<bdo-trustpilot *ngIf="trustpilotUniqueUrl"
                [trustpilotUniqueUrl]="trustpilotUniqueUrl">
</bdo-trustpilot>

<bdo-netcologne-teaser
  *ngIf="!tariffService.isEffzeh([tariffData?.productId])"
  [postCode]="personalData?.addressData?.postCode"
  [trackingLocation]="TRACKING.LOCATION.DELIVERY_CONFIRMATION"
></bdo-netcologne-teaser>

<bdo-effzeh-strom-teaser [productIds]="[tariffData?.productId]"></bdo-effzeh-strom-teaser>

