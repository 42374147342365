
  <div class="re-form-item-container">
    <h4 class="re-mb-10">{{ 'tmh.consumption.heading' | translate }}</h4>
    <div class="bold re-mb-20">{{ 'tmh.consumption.description' | translate }}</div>
  </div>

  <div [formGroup]="parentForm">
    <fieldset>
      <div class="re-form-item-container">
        <bdo-input [formControl]="parentForm.controls.manufacturer"
          ngDefaultControl
          [inputType]="INPUT_TYPE.TEXT"
          [labelText]="'tmh.consumption.producer' | translate"
          [enableAutocomplete]="true"
          [id]="'manufacturer'"
          (blur)="updateCarModels(parentForm.controls.manufacturer.value)"
          #manufacturer>
        </bdo-input>
        <bdo-autosuggest
          *ngIf="manufacturers$"
          [dataSource$]="manufacturers$"
          [searchString$]="manufacturerSearchString$"
          [for]="manufacturer"
          [sort]="sortManufacturer"
          [showOffset]="-1"
          (itemSelected)="onManufacturerSelected($event)">
        </bdo-autosuggest>
      </div>
      <div class="re-form-item-container">
        <bdo-input [formControl]="parentForm.controls.carmodel"
          ngDefaultControl
          [inputType]="INPUT_TYPE.TEXT"
          [labelText]="'tmh.consumption.model' | translate"
          [enableAutocomplete]="true"
          [id]="'carmodel'"
          (blur)="onModelSelected(parentForm.controls.carmodel.value)"
          #carmodel>
        </bdo-input>
        <bdo-autosuggest
          *ngIf="carmodels$"
          [dataSource$]="carmodels$"
          [searchString$]="carmodelSearchString$"
          [for]="carmodel"
          [sort]="sortManufacturer"
          [showOffset]="-1"
          [extraOptionAlwaysVisible]="'tmh.consumption.modelnotfound' | translate"
          (itemSelected)="onModelSelected($event)">
        </bdo-autosuggest>
        <div class="re-mt-20 re-text-sm" *ngIf="compatibility()">
          <ul class="bdo-list__bullet">
            <li>
              <span class="re-mr-10">{{'tmh.consumption.compatibilitytext' | translate: {year:compatibility()} }}</span>
              <bdo-inline-tooltip>
                <span>{{'tmh.consumption.compatibility.info' | translate}}</span>
              </bdo-inline-tooltip>
            </li>
          </ul>
        </div>

        <div *ngIf="parentForm.controls.manufacturer.value?.length > 0 && parentForm.controls.carmodel.value?.length > 0 && !compatibility()?.length"
          class="re-mt-20">
          <bdo-info-box [type]="'warning'">
            <div class="re-mb-15"> {{'tmh.consumption.incompatible' | translate }} </div>
            <div class="re-mb-10">
              <bdo-text-link-icon
                [linkText]="'tmh.consumption.incompatibleContact' | translate"
                [href]="'https://www.rheinenergie.com/chargeflex-fahrzeughersteller'"
                [iconPosition]="'right'"
                [openInNewTab]="true"
                [tracking]="[TRACKING.LOCATION.CHARGEFLEX, TRACKING.ACTION.GOTO, 'Kontaktdaten hinterlegen']"
              ></bdo-text-link-icon>
            </div>
          </bdo-info-box>
        </div>
      </div>
    </fieldset>
  </div>
