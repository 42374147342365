<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>
<div class="bdo-tile bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING" *ngIf="state === LoadingState.LOADING">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
</div>

<div class="re-flex re-flex-row re-items-center re-bg-white re-mb-10 re-w-full re-px-30 re-pt-30 re-pb-45 md:re-w-600 md:re-mx-auto lg:re-w-900"
    *ngIf="state !== LoadingState.LOADING && (!groupedContracts || Object.keys(groupedContracts).length === 0)">
  <svg-icon [applyClass]="true" class="icon re-text-brand-primary re-w-30 re-h-30" src="assets/icons/system_icons/alert.svg"></svg-icon>
  <span class="re-ml-15">{{ 'contract.details.error.noContracts' | translate }}</span>
</div>

<div *ngFor="let contractsOfDivision of groupedContracts | keyvalue" class="re-mb-60" [attr.data-qa]="contractsOfDivision.key + '_group'">
  <h4 class="re-container-with-margin re-mb-30">
    {{ 'contract.titleMy' | translate: { division: contractsOfDivision.key | verbrauchstyp } }}
  </h4>

  <div *ngFor="let contractByMeternumber of contractsOfDivision.value | keyvalue">
    <div [class.re-mb-5]="contractByMeternumber?.value?.length > 1"
        *ngFor="let contract of contractByMeternumber.value; let first = first; let last = last">
      <ng-container *ngIf="first">
        <!-- show info for priceCap -->
        <div *ngIf="showHintPriceCap(contractsOfDivision.value)"
             class="re-w-full re-mt-10 re-mb-3 md:re-w-600 md:re-mx-auto lg:re-w-900">
          <bdo-info-box [type]="'info'" [marginBottom]="false">
            {{ 'contract.overview.priceCapHint' | translate:
              { division: contractsOfDivision.key | verbrauchstyp,
                workPrice: priceCapService.getPriceForDivision(contractsOfDivision.key, customerMode).toString()}
            }}
          </bdo-info-box>
        </div>

        <div class="re-bg-white re-mb-10 re-w-full re-px-20 re-py-15 re-flex re-items-center md:re-w-600 md:re-mx-auto lg:re-w-900 lg:re-px-30 bold">
          <h5>
            {{ 'general.customerData.meternumber' | translate }} <span>{{contractByMeternumber.key}}</span>
          </h5>
        </div>
        <bdo-contract-details-tile
          [accountId]="accountId"
          [contract]="contract"
          [isBaseSupply]="getContractHint(contractsOfDivision.key)?.isBaseSupply"
          [mode]="cancelMode ? ContractDetailsTileMode.CANCEL_CONTRACT : ContractDetailsTileMode.CHANGE_CONTRACT"
          [customerMode]="customerMode"
          [showPriceCap]="priceCapService.getContractAffectedByPriceCap(contract, customerMode)"
          [isNsh1Nsh2Tariff]="getContractNsh(contract.contractId)"
          (tariffCancel)="onCancelContract($event)"
          (tariffChange)="onChangeTariff($event)"
        ></bdo-contract-details-tile>
      </ng-container>
      <bdo-contract-details-small-tile
        *ngIf="!first"
        [contract]="contract"
        [accountId]="accountId"
        [customerMode]="customerMode"
        [changeReason]="getChangeReason(contract, $any(contractByMeternumber.value))"
        [showPriceCap]="priceCapService.getContractAffectedByPriceCap(contract, customerMode)"
      ></bdo-contract-details-small-tile>
    </div>
  </div>
  <!-- show total amount if more than one contract for division -->
  <div class="re-bg-white re-mb-10 re-mt-10 re-w-full re-p-30 re-flex re-items-center md:re-w-600 md:re-mx-auto lg:re-w-900 bold">
    <h5>{{ 'contract.details.billingPerMonth' | translate }}</h5>
    <span class="h2 re-ml-auto">{{ getTotalAmountOfDivision(contractsOfDivision) | currency: 'EUR' }}</span>
  </div>

  <!-- TMH Charge Flex Teaser -->
  <div class="re-container-without-margin re-mt-20"
    *ngIf="isRE && contractsOfDivision.key === 'S4' && customerMode === 'PRIV'">
    <bdo-info-teaser
      data-qa="chargeFlexTeaser"
      [title]="'tmh.contractoverview.title' | translate"
      [hasButton]="false"
      [iconSrc]="'/assets/icons/wallbox_75x75.svg'"
      [tracking]="[TRACKING.LOCATION.CONTRACT_OVERVIEW, TRACKING.ACTION.GOTO, 'ChargeFlex']"
    >
      <div class="re-mb-30" [innerHTML]="'tmh.contractoverview.description' | translate"
        (click)="trackIt()"
      ></div>
      <bdo-text-icon
        data-qa="chargeFlexLink"
        [iconPosition]="'right'"
        [text]="'tmh.contractoverview.linktext' | translate"
        (itemClicked)="setChargeFlexContext()"
      ></bdo-text-icon>
    </bdo-info-teaser>
  </div>
</div>

<div *ngIf="additionalServices">
  <h4 class="re-container-with-margin re-mb-20 md:re-mb-30">
    {{ 'contract.overview.additionalServices' | translate }}
  </h4>
  <div class="re-bg-white re-w-full re-px-30 re-py-20 md:re-w-600 md:re-mx-auto lg:re-w-900">
    <div class="re-tariff-row last:re-mb-0" *ngFor="let item of additionalServices?.contractLevels">
      <div class="re-relative re-pr-30">{{item.description}}</div>
      <div>{{ (additionalServices?.customerType === CUSTOMERMODE.GEWE ?  item.baseNet : item.baseGross) | currency: 'EUR' }}</div>
    </div>
  </div>
</div>
