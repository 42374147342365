import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bdo-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})

export class ToggleButtonComponent {

  @Input() enabled: boolean;
  @Input() id: string;
  @Input() description: string;
  @Input() labelLeft: string;
  @Input() labelRight: string;
  @Output() toggle = new EventEmitter<string>();

  constructor() {
  }

  public changeOption() {
    this.toggle.emit(this.id);
  }

}
