<!-- Old Contract -->
<h5 class="re-mb-15">{{ 'summary.contract.old.title' | translate: { division: oldContract?.division | verbrauchstyp } }}</h5>

<div class="re-summary-row-nowrap">
  <div>{{ 'contract.previous' | translate }}</div>
  <div>{{oldContract?.tariffName}}</div>
</div>

<div class="re-summary-row-nowrap">
  <div>{{ 'summary.contract.old.until' | translate }}</div>
  <div data-qa="contract-end-date">{{calculatedContractEndDate | date}}</div>
</div>

<!-- New Contract -->
<h5 class="re-mb-15 re-mt-30">{{ 'summary.contract.new.title' | translate }}</h5>

<div class="re-summary-row-nowrap">
  <div>{{ 'summary.tariff.newTariff' | translate }}</div>
  <div>{{newTariff?.selectedTariff?.name}}</div>
</div>

<div class="re-summary-row-nowrap">
  <div>{{ 'summary.contract.new.start' | translate }}</div>
  <div data-qa="contract-start-date">{{newTariff?.contractStartDate| date}}</div>
</div>

<div *ngIf="chargeFlexData?.isChargeFlexContext" class="re-summary-row-nowrap">
  <div>{{ 'tmh.summary.model' | translate }}</div>
  <div data-qa="contract-start-date">{{chargeFlexData.manufacturer}} {{chargeFlexData.carmodel}}</div>
</div>

<h5 class="re-mb-15 re-mt-30">{{ 'general.tariff.meterWithDivision' | translate: { division: oldContract?.division | verbrauchstyp } }}
</h5>
<div *ngFor="let meterNumber of oldContract?.meterNumbers">
  <div class="re-summary-row-nowrap">
    <div>{{ 'general.customerData.meternumber' | translate }}</div>
    <div>{{meterNumber}}</div>
  </div>
</div>
