<div *ngIf="items" class="re-relative re-w-full re-z-30" [class.re-hidden]="!(show && focused)">
  <div [style.max-height]="state === LoadingState.ERROR && !extraOptionAlwaysVisible ? '100px' : calculateHeight(items)"
       [style.height]="state === LoadingState.ERROR && !extraOptionAlwaysVisible ? '100px' : calculateHeight(items)"
       [class.re-table]="state === LoadingState.ERROR"
       [class.re-overflow-scroll]="items.length > 6"
       [class.re-scroll-mb-50]="extraOptionAlwaysVisible && items.length > maxElements"
       class="re-absolute re-w-full re-overflow-hidden re-mb-3 re-overflow-x-hidden
              re-border-monochrome-dark re-bg-monochrome-light
              re-border re-border-1 re-border-t-0"
  >
    <div *ngIf="state === LoadingState.ERROR && !extraOptionAlwaysVisible?.length" class="suggestitem re-cursor-pointer hover:re-bg-monochrome-medium-light re-px-15 re-h-50 re-flex re-flex-row re-items-center re-align-middle"
      [class.re-table-cell]="!extraOptionAlwaysVisible?.length"
    >
      <span class="no-results">{{ 'general.validator.autosuggestNoResult' | translate }}</span>
    </div>
    <div *ngFor="let item of items; let i = index; let last = last;" #items
         (mouseenter)="markAsSelected(i)"
         (mouseleave)="markAsUnselected()"
         (mousedown)="onClick(i)"
         (touchend)="onClick(i)"
         (touchmove)="onSwipe(i)"
         [class.re-bg-monochrome-medium-light]="item.selected"

         [class.re-scroll-mb-50]="extraOptionAlwaysVisible && i >= maxElements"
         class="suggestitem re-cursor-pointer re-px-15 re-h-50 re-flex re-flex-row re-items-center">
      <span class="re-truncate">
        <span [innerHTML]="item.before"></span><span [innerHTML]="item.searchstring" class="re-font-regular"></span><span [innerHTML]="item.after"></span>
      </span>
    </div>
    <div *ngIf="extraOptionAlwaysVisible?.length"
      class="re-sticky re-bottom-0 suggestitem re-cursor-pointer re-px-15 re-h-50 re-bg-monochrome-light hover:re-bg-monochrome-medium-light"
      [class.re-bg-monochrome-medium-light]="selectedExtra"
      (mousedown)="itemSelected.emit(extraOptionAlwaysVisible)"
      (touchend)="itemSelected.emit(extraOptionAlwaysVisible)"
      (mouseenter)="markAsUnselected()">
      <div class="re-h-50 re-flex re-flex-row re-items-center"
        [ngClass]="{'re-border-t re-border-monochrome-dark' : items.length > 0}">
        <span class="re-truncate">
          {{ extraOptionAlwaysVisible }}
        </span>
      </div>
    </div>
  </div>
</div>
