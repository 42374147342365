<button
  class="re-group re-flex re-cursor-pointer re-h-30 re-my-20 re-text-left"
  [title]="description"
  [attr.data-qa]="'option_' + id"
  (click)="changeOption()"
  >
  <span *ngIf="labelLeft" class="re-pr-15 re-leading-l">{{ labelLeft }}</span>
  <span
    class="re-rounded-full re-min-w-60 re-w-60 re-h-30 re-relative group-hover:re-bg-secondary re-transition"
    [ngClass]="{
      're-right-0 re-bg-primary': enabled,
      're-left re-bg-monochrome-medium': !enabled
    }">
    <span
      class="toggle_dot re-rounded-full re-w-24 re-h-24 re-bg-white re-block re-ml-3 re-absolute re-mr-3 re-mt-3 re-top-0"
      [class.re-right-0]="enabled"
    ></span>
  </span>
  <span *ngIf="labelRight" class="re-pl-15 re-leading-l">{{ labelRight }}</span>
</button>
