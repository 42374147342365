import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import {
  Address,
  AddressData,
  CampaignInfo,
  ContractChangeTariff,
  ContractConsumptionSummary,
  ContractDetails,
  ContractInformationSummary,
  TariffData,
  TariffSummary,
  TariffSummaryResults
} from '../../../../assets/js/com/ts_api_client';
import { FadeInAnimation } from '../../../shared/animations/fade-in.animation';
import { Utilities } from '../../../shared/utils/utilities';
import { CUSTOMERMODE } from '../../enums/customerMode';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { TRACKING } from '../../enums/trackingParts.enum';
import { VERBRAUCHSTYP } from '../../enums/verbrauchstyp.enum';
import { BdoApiService } from '../../services/bdo-api.service';
import { CustomerStoreService } from '../../services/customer-store.service';
import { ALLOWED_KEYS, StorageService } from '../../services/storage.service';
import { TariffAdvisorService } from '../../services/tariff-advisor.service';
import { TrackingService } from '../../services/tracking.service';
import { LoginService } from '../../../login/login.service';
import { DatadogService } from '../../services/datadog.service';
import { toNumber } from 'lodash';
import { AuthDataStorage } from '../../models/AuthData.storage';
import { OFFER_CONTEXT, OFFER_CONTEXT_SPECIAL } from '../../enums/offer-context';
import { TariffSelection } from '../../../shared/models/tariff-selection';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { ConsumptionFormtype } from '../../../shared/formtypes/consumption.formtype';
import { TariffSummaryParams } from '../../models/TariffSummaryParams';


@Component({
  selector: 'bdo-tariff-selection',
  templateUrl: './tariff-selection.component.html',
  styleUrls: ['./tariff-selection.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class TariffSelectionComponent implements OnInit {

  @Input() title: string;
  @Input() copytext: string;
  @Input() showRecentTariff: boolean = false;
  @Input() linkForTextLinkIcon: string;
  @Input() newTariffTitle: string;
  @Input() isConsumptionChangeable: boolean = true;
  @Input() tariffSelectionLink: string;
  @Input() trackingLocation: TRACKING.LOCATION;
  public form = new FormGroup<Partial<ConsumptionFormtype>>({});
  public campaignContext: boolean = false;
  public accountId: string;
  public divisionId: string;
  public meterNumber: string;
  public annualConsumption: number = 2500;
  public chargingConsumption: number = null;
  public oldContract: ContractDetails;
  public address: AddressData;
  public tariffs: Array<TariffSummaryResults> = [];
  public tariffsWithOptions: Array<ContractChangeTariff>;
  public tilesShown: boolean = false;
  public state = LOADING_STATE.IDLE;
  public stateConsumption = LOADING_STATE.IDLE;
  public stateOldContract = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public customerMode: string;
  public CUSTOMERMODE = CUSTOMERMODE;
  public oldMonthlyPrice: number;
  public offerContext: OFFER_CONTEXT | OFFER_CONTEXT_SPECIAL;
  public isInEditMode: boolean = false;
  private bannerId: string;
  private contractBeginDate: Date;
  private productId: string;
  private authData: AuthDataStorage;

  constructor(
    public tariffAdvisorService: TariffAdvisorService,
    public apiService: BdoApiService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private customerStore: CustomerStoreService,
    private trackingService: TrackingService,
    private loginService: LoginService,
    private dataDogService: DatadogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    if (StorageService.getChargeFlexContext()) {
      this.isInEditMode = true;
    }
    this.trackingService.postTracking(this.trackingLocation, TRACKING.ACTION.ENTER);
    this.authData = StorageService.getAuthData();
    this.offerContext = StorageService.getOfferContext();
    this.address = Utilities.mapPersonalAddressDataToAddressData(StorageService.getPersonalData()?.addressData);
    this.oldContract = StorageService.getOldContract();
    const moveTariffData: TariffSelection = StorageService.getFirstTariffSelection();
    const tariffData: TariffData = StorageService.getTariffData()?.[0];

    this.meterNumber = this.authData ? this.authData.checkIdentificationData.meterNumberOrRegisterCode :
                       (this.oldContract?.meterNumbers[0] || StorageService.getSituationData()?.[0]?.meterNumber);
    this.divisionId = this.oldContract?.division || tariffData?.divisionId;
    this.annualConsumption = moveTariffData?.consumption || toNumber(tariffData?.annualConsumption);
    this.productId = moveTariffData?.selectedTariff?.productId || tariffData?.productId;
    this.accountId = this.customerStore.getAccountId();
    this.bannerId = this.activatedRoute?.snapshot?.queryParams['bannerId'];
    this.campaignContext = !!this.bannerId;

    if (this.campaignContext) {
      this.isConsumptionChangeable = false;
    }

    this.stateConsumption = this.annualConsumption ? LOADING_STATE.IDLE : LOADING_STATE.LOADING;
    if (this.campaignContext && (!this.divisionId || !this.productId)) {
      this.stateOldContract = this.oldContract ? LOADING_STATE.IDLE : LOADING_STATE.LOADING;
      this.apiService.getCampaignInfos(this.accountId).pipe(
        switchMap((campaigns: Array<CampaignInfo>) => {
          const campaignInfo = campaigns.find(campaign => campaign.bannerId === this.bannerId);
          this.productId = campaignInfo.productId;
          this.contractBeginDate = new Date(campaignInfo.campaignStartDate);
          this.divisionId = campaignInfo.divisionId;
          return this.apiService.getContracts(this.accountId);
        })
      ).subscribe(
        {
          next: (res: ContractInformationSummary) => {
            const contractsWithoutZUAA = res.contractDetails.filter(contract => contract.contractId !== 'ZUAA');
            this.oldContract = contractsWithoutZUAA.filter(contract => contract.division === this.divisionId)[0];
            this.meterNumber = this.oldContract?.meterNumbers?.[0];
            StorageService.setOldContract(this.oldContract);
            this.getConsumption();
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
            this.stateConsumption = LOADING_STATE.ERROR;
          }
        });
    } else if (this.offerContext) {
      this.contractBeginDate = StorageService.getSituationData()?.startDate;
      this.getConsumption();
    } else {
      /* Contract starts one day after guaranteeEndDat.
         If we have no guaranteeEndDat or guaranteeEndDat is less today, we use tomorrow */
      const nextDayOfGuaranteeEndDate = moment(this.oldContract?.additionalTariffInformation?.guaranteeEndDate).add(1, 'days').toDate();
      const useGuaranteeEndDate: boolean = this.oldContract?.additionalTariffInformation?.guaranteeEndDate &&
                                        this.oldContract?.additionalTariffInformation?.guaranteeEndDate >= moment().toDate();
      this.contractBeginDate = useGuaranteeEndDate ? nextDayOfGuaranteeEndDate : moment().add(1, 'day').toDate();
      this.getConsumption();
    }
  }

  getConsumption() {
    const authData = StorageService.getAuthData();

    this.loginService.isLoggedIn$.pipe(
      first(),
      switchMap((loggedIn) => {
        let observable: Observable<[Address, ContractConsumptionSummary]>;
        const { accountId, meterNumberOrRegisterCode } = { ...authData?.checkIdentificationData };
        if (this.offerContext) {
          const address: Address = Utilities.nestAddress(this.address);
          const consumption: ContractConsumptionSummary = {
            contractDetails: [
              {
                accountId: this.accountId,
                divisionId: this.divisionId,
                consumption: this.annualConsumption
              }
            ]
          };
          observable = of([address, consumption]);
        } else if (loggedIn) {
          observable = forkJoin([
            this.apiService.getAccountInfo(this.accountId).pipe(map((account) => account?.address)),
            this.apiService.getContractConsumption(this.accountId)
          ]);
        } else if (authData) {
          observable = forkJoin([
            this.apiService.getAddressAnonymous(accountId, meterNumberOrRegisterCode).pipe(map((allAddress) => allAddress.premiseAddress)),
            this.apiService.getContractConsumptionAnonymous(accountId)
          ]);
        } else {
          observable = EMPTY; // complete observable
        }
        return observable;
      }),
      tap(
        { next: ([address, consumptions]) => {
          this.address = Utilities.flattenAddress(address);
          this.annualConsumption = this.annualConsumption || consumptions?.contractDetails.find(elem => elem.divisionId === this.divisionId)?.consumption;
          this.stateConsumption = LOADING_STATE.IDLE;
        } }
      ),
      switchMap(() => this.showRecentTariff ? this.getOldContract() : of({})),
    ).subscribe({
      next: () => {
        // Prevent loading tariffs initially for chargeflex as we first need the charging consumption
        if (!StorageService.getChargeFlexContext() || this.chargingConsumption) {
          this.recalculateTariffs(this.annualConsumption, false);
        }
      }
    });
  }

  onEdit(isInEditMode: boolean) {
    this.tilesShown = !isInEditMode;
  }

  getOldContract(): Observable<ContractInformationSummary | null> {
    this.stateOldContract = LOADING_STATE.LOADING;
    const accountId: string = this.authData ? this.authData.checkIdentificationData.accountId : this.accountId;
    return this.apiService.getContractsAnonymous(accountId, this.meterNumber, this.annualConsumption)
      .pipe(
        tap((res: ContractInformationSummary) => {
          const contractsWithoutZUAA = res.contractDetails.filter(contract => contract.contractId !== 'ZUAA');
          this.oldContract = this.meterNumber ? contractsWithoutZUAA.filter(contract => contract.meterNumbers?.includes(this.meterNumber))[0] :
            contractsWithoutZUAA.filter(contract => contract.division === this.divisionId)[0];
          if (this.meterNumber) {
            this.oldContract.meterNumbers = [ this.meterNumber ];
          }
          StorageService.setOldContract(this.oldContract);
          this.oldMonthlyPrice = this.customerMode === CUSTOMERMODE.GEWE ? this.oldContract.monthlyPriceNet : this.oldContract.monthlyPriceGross;
          this.stateOldContract = LOADING_STATE.IDLE;
        }),
        catchError((error: unknown) => {
          this.stateOldContract = LOADING_STATE.ERROR;
          return throwError(() => error); // rethrow, without oldContract we can't continue
        })
      );
  }

  /**
   * recalculate tariffs based on consumption
   * @param consumption
   * @param recalculateOldContract force recalculation of old contract if consumption changes
   */
  public recalculateTariffs(consumption: number, recalculateOldContract: boolean = true){
    const authData = StorageService.getAuthData();
    this.annualConsumption = consumption;
    const selectedCarIsCompatible = StorageService.getChargeFlexData()?.compatibility;
    const showChargeFlexOnly = this.chargingConsumption && selectedCarIsCompatible;
    this.tariffs = [];
    this.state = LOADING_STATE.LOADING;

    if (this.showRecentTariff && recalculateOldContract) { // avoid calling getOldContract() twice
      this.getOldContract().subscribe(); // we don't care about the result, it's just for sideeffects
    }

    // get tariff for campaign or get tariffs for available tariffs
    if (this.campaignContext || this.offerContext) {
      this.getTariffInformation(Array.of(this.productId))
        .subscribe(
          {
            next: (tariff: TariffSummary) => {
              const tariffData = tariff.results.find(tariffResult => tariffResult.productId === this.productId);
              if (this.campaignContext) {
                tariffData.badge.type = 'economic';
                tariffData.badge.text = 'Unser Bestseller';
              }
              this.customerMode = tariffData.customerType;
              this.tariffs.push(tariffData);
              this.tilesShown = true;
              this.state = LOADING_STATE.IDLE;
            },
            error: () => {
              this.state = LOADING_STATE.ERROR;
            }
          }
        );
    } else {
      const prodConstIds: Array<string> = [];
      let tariffObservable: Observable<TariffSummary>;
      if (showChargeFlexOnly){
        tariffObservable = this.loginService.isLoggedIn$.pipe(
          first(),
          switchMap(() => {
            return this.getTariffInformation([this.tariffAdvisorService.getFlexTariffId()]);
          })
        );
      } else {
        tariffObservable = this.loginService.isLoggedIn$.pipe(
          first(),
          switchMap((loggedIn) => {
            return loggedIn ? this.apiService.getContractOptions(this.accountId,this.oldContract.contractId, this.divisionId, this.annualConsumption, undefined) :
              this.apiService.getContractOptionsAnonymous(
                this.accountId,
                authData?.checkIdentificationData?.meterNumberOrRegisterCode,
                this.oldContract.contractId,
                this.divisionId,
                this.annualConsumption,
                undefined
              );
          }),
          tap({ next: (changeTariffs: Array<ContractChangeTariff>) => this.tariffsWithOptions = changeTariffs }),
          map(() => this.tariffsWithOptions.forEach(tariff => prodConstIds.push(tariff.noOptionsProductID))),
          switchMap(() => {
            return this.getTariffInformation(prodConstIds);
          }));
      }

      tariffObservable.subscribe({
        next: (tariffs: TariffSummary) => {
          if (showChargeFlexOnly){
            this.tariffs.push(tariffs.results[0]);
            this.tilesShown = true;
            this.state = LOADING_STATE.IDLE;
          } else {
            this.tariffsWithOptions.forEach(changeTariff => {
              let tariffData: TariffSummaryResults = tariffs.results.find(tariff => tariff.productId === changeTariff.noOptionsProductID);

              // if no tariff in cms was found, go to next tariff and track the productId
              if (!tariffData) {
                this.dataDogService.trackCustomEvent('contractEdit__tariffAdvisor__optionProdConstIdNotAvailableCMS__' + changeTariff.noOptionsProductID);
                return;
              }
              // if no parts in sap was found, go to next tariff and track the productId
              if (tariffData.parts?.length === 0) {
                this.dataDogService.trackCustomEvent('contractEdit__tariffAdvisor__optionProdConstIdNotAvailableNoParts__' + changeTariff.noOptionsProductID);
                return;
              }

              tariffData.frameContractId = changeTariff.frameContractID;
              // set benefits for certain tariffs
              switch (tariffData.frameContractId) {
                case 'REGHNFRP':
                case 'RESHNFRP':
                  tariffData.benefits = [ 'Unser Rahmenvertrag mit individuellen Optionen' ];
                  break;
                default:
                  break;
              }

              this.tariffs.push(tariffData);
              // set customerMode despite the fact, that all ContractChangeTariffs have the same customerMode, because
              // it is calculated based on the "Lastprofil" of the customer, not by contract
              this.customerMode = tariffData.customerType;
              this.tilesShown = true;
              this.state = LOADING_STATE.IDLE;
            });
          }

        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
    }
  }

  onCalculate(consumption: number, chargingConsumption?: number) {
    if (chargingConsumption) {
      this.chargingConsumption = chargingConsumption;
      StorageService.updateChargeFlexData({
        carmodel: this.form.controls.carmodel?.value,
        manufacturer: this.form.controls.manufacturer?.value,
        chargingConsumption: parseInt(this.form.controls.chargingConsumption?.value as any, 10), // should be a number but will be saved as string otherwise
        consumptionPer100: this.form.controls.consumptionPer100?.value,
        kmPerYear: this.form.controls.kmPerYear?.value,
        homeChargingPercentage: this.form.controls.homeChargingPercentage?.value
      });
    }

    return this.recalculateTariffs(consumption);
  }

  getOptionInfoText(optionId: string): string {
    switch (optionId) {
      case 'REGXOÖKO': return this.translateService.instant('tariff.optionInfo.REGXOÖKO');
      case 'RESHORHE': return this.translateService.instant('tariff.optionInfo.RESHORHE');
      case 'REGHOK23': return this.translateService.instant('tariff.optionInfo.REGHOK23');
      default: return null;
    }
  }

  onTariffSelected(tariffTile: TariffSummaryResults) {
    const tariffSelection: TariffSelection = {
      type: this.divisionId as VERBRAUCHSTYP,
      isSelected: true,
      consumption: parseInt(tariffTile.annualConsumption, 10),
      numberOfMeterNumbers: 1,
      selectedTariff: tariffTile,
      monthlyPrice: tariffTile.parts[0].periodicPrices[0].monthlyPriceNet,
      divisionConfiguration: {
        id: '',
        title: '',
        iconSrc: '',
        active: true,
        completed: true
      },
      contractStartDate: this.contractBeginDate
    };

    if (this.offerContext) {
      const tariffData: TariffData[] = [{
        annualConsumption: tariffTile.annualConsumption,
        postCode: this.address.postCode,
        hash: StorageService.getTariffData()?.[0]?.hash,
        type: StorageService.getTariffData()?.[0]?.type,
        mode: StorageService.getTariffData()?.[0]?.mode,
        divisionId:  this.divisionId,
        productId: tariffTile.productId,
        tariff: StorageService.getTariffData()?.[0]?.tariff,
        tbTariffName: StorageService.getTariffData()?.[0]?.tbTariffName
      }];
      StorageService.setTariffData(tariffData);
    }
    StorageService.setTariffSelections([tariffSelection]);


    if (this.tariffSelectionLink === '../uebersicht' && !this.offerContext) {
      StorageService.setPersonalData({ addressData: {
        city: this.address.cityName,
        housenumber: this.address.houseNum,
        postCode: this.address.postCode, // we need this for netcologne teaser later
        street: this.address.streetName,
      } });
    }

    if (this.showRecentTariff) {
      StorageService.setProperty(ALLOWED_KEYS.OLD_CONTRACT, 'contractEndDate', this.contractBeginDate ? this.contractBeginDate : Date.now());
    }

    this.trackingService.postSimpleTracking(this.trackingLocation, TRACKING.ACTION.GOTO,
      this.offerContext ? TRACKING.LOCATION.DELIVERY_PERSONALDATA : TRACKING.LOCATION.CONTRACT_CHANGE_SUMMARY);
    this.router.navigate([this.tariffSelectionLink], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
  }

  onChangeOption($event: { optionId: string; enabled: boolean, frameContractId: string }) {
 /*    const modifiedTariff = this.tariffs.find((tariff) => tariff.frameContractId === $event.frameContractId);
    if (modifiedTariff) {
      const activatedOptions = modifiedTariff.options.filter((option) => option.enabled).map((option) => option.data.optionId);
      const optionString = activatedOptions.length ? activatedOptions.join(',') : undefined;
      this.apiService.getProductIdForFrameContract(this.accountId, $event.frameContractId, optionString)
        .subscribe((result: StatusResponse) => {
          this.tariffs = this.tariffs.map((tariff) => {
            if (tariff.frameContractId !== $event.frameContractId) {
              return tariff;
            } else {
              return {
                ...tariff,
                prodConstIds: {
                  Development: result.info,
                  Akzeptanz: result.info,
                  Production: result.info
                }
              };
            }
          });
        });
    } */
  }

  getTariffInformation(productIds: Array<string>): Observable<TariffSummary> {
    const tariffSummaryParams: TariffSummaryParams = {
      beginDate: moment(this.contractBeginDate).format('yyyy-MM-DD').toString(),
      division: this.divisionId,
      annualConsumption: this.annualConsumption,
      postCode: this.address.postCode,
      productIds,
      cityName: this.address.cityName,
      streetName: this.address.streetName,
      houseNum: this.address.houseNum,
      customerMode: this.customerMode,
      chargingConsumption: this.chargingConsumption || null,
      offerId: StorageService.getOfferId() || null
    };
    return this.apiService.getTariff(tariffSummaryParams);
  }
}
