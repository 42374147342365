import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ReadonlyTenantsService } from '../bdo/services/readonly-tenants.service';
import { Environment } from '../../environments/environment';
import { TENANT } from '../bdo/enums/tenant.enum';

export const readonlyGuard: CanActivateFn = (route, state) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const readonlyTenantsService = inject(ReadonlyTenantsService);
  if (readonlyTenantsService.isCurrentTenantReadonly()) {
    if (Environment.tenant === TENANT.Belkaw) {
      window.location.href = 'https://belkaw.de/kundenportal-Systemupdate';
    } else {
      return router.navigate(['baustelle']);
    }
    return false;
  } else {
    return true;
  }
};
