<div class="re-bg-white">

  <div *ngFor="let pricePart of prices; let first = first; let indexOfPrice = index;">
    <hr *ngIf="!first" class="re-border-t-3 re--mx-20 re-border-monochrome-light md:re--mx-30">
      <bdo-single-accordion
        [shortInfo]="'summary.tariff.priceExpand' | translate"
        [arrowRight]="true"
        [isOpenInitially]="first"
        [buttonStyles]="'re-px-20 md:re-px-30 re-pt-10 md:re-pt-15 re-pb-10 md:re-pb-15'"
        [attr.data-qa]="'tariffdetails' + indexOfPrice">
        <div slot="customHeader" class="re-inline-block re-justify-center re-text-justify re-mr-auto">
          <h5 class="bold re-inline-block re-mr-5">
            {{(hasPriceCap ? pricePart.priceCapYear.monthlyPrice : pricePart.firstYear.monthlyPrice) | currency: 'EUR'}}
          </h5>
          <div class="re-inline-block re-xs">{{ 'tariff.perMonth' | translate }}
            <span *ngIf="prices.length > 1">{{first ? 'bis' : 'ab'}}
              <span class="bold">{{(first ? pricePart?.priceEndDate : pricePart?.priceStartDate) | date }}</span>
            </span>
          </div>
        </div>

        <hr class="re-mb-15 re-border-t re-border-monochrome-medium-light">

        <div class="re-px-20 md:re-px-30">
          <!-- Consumption / Consumption HT -->
          <div class="re-tariff-row">
            <div class="re-relative re-pr-30">
              <ng-container *ngIf="hasMissingNtConsumptionForHTNT; else nohtnt"> {{ 'tariff.overview.consumptionCalculated' | translate }}</ng-container>
              <ng-template #nohtnt>{{ 'tariff.overview.consumption' | translate }}</ng-template>
              <span *ngIf="isHTNT && tariffData.annualConsumption && tariffData.annualConsumptionNt">({{ 'meterreadings.tariffs.high' | translate }})</span>
              <span *ngIf="hasMissingNtConsumptionForHTNT">({{ 'meterreadings.tariffs.highAndLow' | translate }})
                <bdo-inline-tooltip>
                  <span [innerHtml]="'tariff.overview.consumptionCalculatedHTNTHint' | translate"></span>
                </bdo-inline-tooltip></span>
            </div>
            <div>{{tariffData.annualConsumption| number}} {{tariffData?.division | divisionToUnit: "billing"}}</div>
          </div>

          <!-- Working price HT, if double tarif meter -->
          <bdo-tariff-prices-workingprice *ngIf="isHTNT"
            [price]="tariffAdvisorService?.getHTNTPrice('HT', isGewe, tariffData?.parts?.[indexOfPrice]?.contractSums)"
            [description]="('general.tariff.workingPriceInUnit' | translate: { unit: tariffData?.division | divisionToUnit: 'billing'}) + ' (HT)'"
            [textInfoI]="('contract.details.costs.workingPriceHintHT' | translate) + ' ' +
                         ('contract.details.costs.workingPriceHint' | translate)"
          ></bdo-tariff-prices-workingprice>

          <!-- Consumption NT -->
          <div class="re-tariff-row" *ngIf="tariffData?.annualConsumptionNt">
            <div class="re-relative re-pr-30">
              {{ 'tariff.overview.consumption' | translate }} ({{ 'meterreadings.tariffs.low' | translate }})
            </div>
            <div>{{tariffData?.annualConsumptionNt | number}} {{tariffData?.division | divisionToUnit: "billing"}}</div>
          </div>

          <!-- Working price NT, if double tarif meter -->
          <bdo-tariff-prices-workingprice *ngIf="isHTNT"
            [price]="tariffAdvisorService?.getHTNTPrice('NT', isGewe, tariffData?.parts?.[indexOfPrice]?.contractSums)"
            [description]="('general.tariff.workingPriceInUnit' | translate: { unit: tariffData?.division | divisionToUnit: 'billing'}) + ' (NT)'"
            [textInfoI]="('contract.details.costs.workingPriceHintNT' | translate) + ' ' +
                         ('contract.details.costs.workingPriceHint' | translate)"
          ></bdo-tariff-prices-workingprice>

          <!-- WorkingPrice -->
          <div *ngFor="let workingPrice of tariffData?.parts?.[indexOfPrice]?.contractSums">
            <bdo-tariff-prices-workingprice *ngIf="!isHTNT"
              [price]="isGewe ? workingPrice?.workNet : workingPrice?.workGross"
              [description]="('general.tariff.workingPriceInUnit' | translate: { unit: tariffData?.division | divisionToUnit: 'billing'})"
              [textInfoI]="'contract.details.costs.workingPriceHint' | translate"
            ></bdo-tariff-prices-workingprice>
          </div>

          <!-- BasePrice -->
          <div *ngFor="let option of tariffData?.parts?.[indexOfPrice]?.contractLevel">

            <div *ngIf="option.level === 1">
              <div class="re-tariff-row">
                <div class="re-relative re-pr-30">
                  <span>{{ 'contract.details.costs.yearlyBasePrice' | translate }}</span>
                  <span class="re-absolute re-ml-10" *ngIf="infoWorkprice">
                      <bdo-inline-tooltip>
                        <span [innerHtml]="infoBaseprice"></span>
                      </bdo-inline-tooltip>
                    </span>
                </div>
                <div>+ {{(isGewe ? option.baseNet : option.baseGross) | currency: 'EUR'}}</div>
              </div>
            </div>

            <!-- MeterPrice -->
            <div class="re-tariff-row" *ngIf="option.level === 15">
              <div class="re-relative re-pr-30">
                <span>{{ 'contract.details.costs.meterPrice' | translate }}</span>
                <span class="re-absolute re-ml-10">
                  <bdo-inline-tooltip>
                    <div [innerHTML]="'meter-price-info_' + tariffData?.productId"></div>
                  </bdo-inline-tooltip>
                </span>
              </div>
              <div>+ {{(isGewe ? option.baseNet : option.baseGross) | currency: 'EUR'}}</div>
            </div>

            <!-- other options with basePrice -->
            <div class="re-tariff-row" *ngIf="option.baseGross !== null && option.baseNet !== null && option.level !== 1 && option.level !== 15">
              <div>{{ option?.description }}</div>
              <div>+ {{(isGewe ? option.baseNet : option.baseGross) | currency: 'EUR'}}</div>
            </div>
          </div>

          <!-- Bonus -->
          <ng-container *ngFor="let bonus of tariffData?.parts?.[indexOfPrice]?.contractPayments">
            <div class="re-tariff-row" *ngIf="!bonus.description.includes(KEY_FOR_CHARGE_FLEX_BONUS)">
              <div class="re-relative re-pr-30">
                <span class="re-text-success">{{ bonus?.description }}</span>
                <span class="re-absolute re-ml-10">
                    <bdo-inline-tooltip data-name="bonus-price-info">
                      <span [innerHtml]="'contract.details.costs.bonusHint' | translate"></span>
                    </bdo-inline-tooltip>
                  </span>
              </div>
              <div class="re-text-success">{{(isGewe ? bonus.baseNet : bonus.baseGross) | currency: 'EUR'}}</div>
            </div>
          </ng-container>

          <!-- ChargeFlex Special Bonus -->
           <ng-container *ngIf="getChargeFlexBonusData(indexOfPrice)">
            <hr class="re-my-15 re-border-t re-border-monochrome-medium-light">
            <div class="re-tariff-row">
              <div> {{ 'tmh.summary.yearlyCostWithoutBonus' | translate }} </div>
              <div class="bold">
                {{pricePart?.secondYear?.annualPrice | currency: 'EUR'}}
              </div>
            </div>
            <div class="re-tariff-row bold">
              <div> {{ 'tmh.summary.monthlyCostWithoutBonus' | translate }} </div>
              <div class="bold re-text-sm md:re-text-m">
                {{pricePart?.secondYear?.monthlyPrice | currency: 'EUR'}}
              </div>
            </div>

            <hr class="re-my-15 re-border-t re-border-monochrome-medium-light">
            <h5 class="re-mb-20">{{'tmh.summary.bonusHeadline' | translate}}</h5>
            <div class="re-tariff-row">
              <div>{{ 'tmh.summary.yearlyConsumption' | translate }}</div>
              <div>{{getChargeFlexBonusData(indexOfPrice)?.quantity | number}} kWh
                <!-- {{getChargeFlexBonusData(indexOfPrice)?.quantityUnit}} -->
              </div>
            </div>
            <div class="re-tariff-row">
              <div>
                {{ 'tmh.summary.flexCredit' | translate }}
                <span class="re-absolute re-ml-10">
                  <bdo-inline-tooltip>
                    {{'tmh.summary.info.flexCredit' | translate}}
                  </bdo-inline-tooltip>
                </span>
              </div>
              <div>× {{getChargeFlexBonusData(indexOfPrice)?.unitPriceGross * 100 | currency:'Cent'}}</div>
            </div>
            <div class="re-tariff-row re-text-green">
              <div>
                {{ 'tmh.summary.bonus' | translate }}
                <span class="re-absolute re-ml-10">
                  <bdo-inline-tooltip>
                    {{'tmh.summary.info.optimizeBonus' | translate}}
                  </bdo-inline-tooltip>
                </span>
              </div>
              <div>{{getChargeFlexBonusData(indexOfPrice)?.baseGross | currency: 'EUR'}}</div>
            </div>

            <hr class="re-my-15 re-border-t re-border-monochrome-medium-light">
            <div class="re-tariff-row re-text-sm">
              <div> {{ 'tmh.summary.yearlyCostWithBonus' | translate }} </div>
              <div class="bold">
                {{pricePart?.firstYear?.annualPrice | currency: 'EUR'}}
              </div>
            </div>
            <div class="re-tariff-row bold re-text-sm md:re-text-m re-pb-20">
              <div class="re-mb-10"> {{ 'tmh.summary.monthlyCostWithBonus' | translate }} </div>
              <div class="bold re-text-m md:re-text-l">
                {{pricePart?.firstYear?.monthlyPrice | currency: 'EUR'}}
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="!getChargeFlexBonusData(indexOfPrice)?.quantity">
            <hr class="re-my-15 re-border-t re-border-monochrome-medium-light">
            <div class="re-tariff-row" *ngIf="hasBonus; else yearWithoutBonus">
              <div>
                {{ 'contract.details.costs.annualPriceBonus' | translate }}
                <bdo-inline-tooltip *ngIf="hasMissingNtConsumptionForHTNT">
                  <span [innerHtml]="'contract.details.costs.priceCaclulationInfo' | translate"></span>
                </bdo-inline-tooltip>
              </div>
              <div class="bold">
                {{pricePart?.firstYear?.annualPrice | currency: 'EUR'}}
              </div>
            </div>
            <ng-template #yearWithoutBonus>
              <div class="re-tariff-row">
                <div>
                  {{ 'contract.details.costs.annualPrice' | translate }}
                  <bdo-inline-tooltip *ngIf="hasMissingNtConsumptionForHTNT">
                    <span [innerHtml]="'contract.details.costs.priceCaclulationInfo' | translate"></span>
                  </bdo-inline-tooltip>
                </div>
                <div class="bold">
                  {{pricePart?.firstYear?.annualPrice | currency: 'EUR'}}
                </div>
              </div>
            </ng-template>


            <div class="re-pb-15">
              <!-- Kosten pro Monat -->
              <ng-container *ngIf="hasBonus; else monthWithoutBonus">

                <!-- headline cost per year -->
                <div class="re-tariff-row re-mb-30">
                  <div class="bold" [ngClass]="{'h5': !hasPriceCap}">
                    {{ 'contract.details.costs.monthlyPriceBonus' | translate }}
                  </div>
                  <div class="bold re-mb-15" [ngClass]="{'h4': !hasPriceCap}">{{pricePart?.firstYear?.monthlyPrice | currency: 'EUR'}}</div>
                </div>

                <!-- yearly cost after first year -->
                <div class="re-tariff-row" *ngIf="pricePart?.secondYear">
                  <div class="re-relative re-pr-30">
                    <span>{{ 'contract.details.costs.annualPriceSecond' | translate }}</span>
                  </div>
                  <div>{{pricePart?.secondYear?.annualPrice | currency: 'EUR'}}</div>
                </div>

                <!-- monthly cost after first year -->
                <div class="re-tariff-row re-mb-0"  *ngIf="pricePart?.secondYear">
                  <div class="re-relative re-pr-30">
                    <span>{{ 'contract.details.costs.monthlyPriceSecond' | translate }}</span>
                  </div>
                  <div>{{pricePart?.secondYear?.monthlyPrice | currency: 'EUR'}}</div>
                </div>

              </ng-container>
              <ng-template #monthWithoutBonus>
                <div class="re-tariff-row re-mb-0">
                  <div class="bold" [ngClass]="{'h5': !hasPriceCap}">{{ 'contract.details.costs.monthlyPrice' | translate }}</div>
                  <div class="bold" [ngClass]="{'h4': !hasPriceCap}">{{pricePart?.firstYear?.monthlyPrice | currency: 'EUR'}}</div>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </div>

        <!-- show price cap -->
        <ng-container *ngIf="tariffData?.parts?.[indexOfPrice]?.priceCap">
          <hr class="re-border-t-3 re--mx-20 re-border-monochrome-light md:re--mx-30">
          <bdo-tariff-price-cap
            [tariffDataParts]="tariffData?.parts?.[indexOfPrice]"
            [isGewe]="isGewe"
            [division]="tariffData?.division"
          ></bdo-tariff-price-cap>
        </ng-container>
      </bdo-single-accordion>

    <ng-container *ngIf="tariffData?.parts.length > 1 && first">
      <hr class="re-border-t-3 re--mx-30 re-border-monochrome-light">
        <bdo-info-box [type]="'info'" [marginBottom]="false">
          <span class="re-hidden md:re-inline">{{ 'tariff.priceAdjustment.hint' | translate }}</span>
          <span class="md:re-hidden re-inline">{{ 'tariff.priceAdjustment.hintSmall' | translate }}</span>
        </bdo-info-box>
    </ng-container>

  </div>
</div>
