import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TrackingService } from '../../../services/tracking.service';
import { SituationData, TariffData } from '../../../../../assets/js/com/ts_api_client';
import { SUBMISSION_ERRORS } from '../summary/summary.component';
import { Location, LocationStrategy } from '@angular/common';
import { TrackingPixelData } from '../../../models/trackingPixelData';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { TrustpilotService } from '../../../services/trustpilot.service';
import { LinksMarketingPixelConfig } from '../../../../../environments/environment';
import { VerbrauchstypPipe } from '../../../pipes/verbrauchstyp.pipe';
import { Utilities } from '../../../../shared/utils/utilities';
import { KameleoonService } from '../../../services/kameleoon.service';
import { filter, first } from 'rxjs/operators';
import { StoragePersonalData } from '../../../models/storagePersonalData';
import { NavigationState } from '../../../models/navigationState';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';

@Component({
  selector: 'bdo-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  public situationData: SituationData;
  public personalData: StoragePersonalData;
  public trackingPixelData: TrackingPixelData;
  public registrationError: boolean;
  public trustpilotUniqueUrl: string;
  public TRACKING = TRACKING;
  public wishvoucherAmount: string;
  public tariffData: TariffData;
  public isChargeFlex = StorageService.getChargeFlexContext();
  private trustpilotSecureToken: string;

  constructor(
    public tariffService: TariffAdvisorService,
    private trustpilotService: TrustpilotService,
    private trackingService: TrackingService,
    private locationStrategy: LocationStrategy,
    private divisionPipe: VerbrauchstypPipe,
    private kameleoonService: KameleoonService,
    private location: Location,
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.registrationError = currentState?.error === SUBMISSION_ERRORS.REGISTRATION_FAILED;
    Utilities.disableBrowsersBackButton(this.locationStrategy);
    this.trustpilotSecureToken = StorageService.getTrustPilotToken();
  }


  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.DELIVERY_CONFIRMATION, TRACKING.ACTION.ENTER);
    this.situationData = StorageService.getSituationData();
    this.personalData = StorageService.getPersonalData();
    this.tariffData = StorageService.getTariffData()?.[0];
    this.trackingPixelData = StorageService.getValue<TrackingPixelData>(ALLOWED_KEYS.TRACKINGPIXEL_DATA);
    this.trustpilotUniqueUrl = this.trustpilotService.buildTrustpilotUniqueUrl(this.trustpilotSecureToken);
    const vouchers = StorageService.getOfferVouchers();
    // Currently there is only one voucher possible
    this.wishvoucherAmount = vouchers?.list?.[0]?.value;

    // build affiliate Pixel call if s_id is set
    if (this.trackingPixelData?.re_channel === 'affiliate') {
      this.buildAffiliatePixel(this.tariffData);
    } else if (this.trackingPixelData?.re_channel === 'fwk' && this.trackingPixelData?.re_partnerid) {
      this.buildTelljaPixel(this.tariffData);
    }
    this.processKameleoonGoal();
    window.sessionStorage.clear();
  }

  private processKameleoonGoal() {
    this.kameleoonService.kameleoonLoaded$.pipe(
      filter((loaded) => !!loaded),
      first()
    ).subscribe({ next: () => {
      this.kameleoonService.processConversion(KameleoonService.Goals.DELIVERY_CONFIRMATION);
    } });
  }

  private buildPixel(src: string) {
    const imgPixel = document.createElement('img');
    imgPixel.setAttribute('alt', '');
    imgPixel.setAttribute('title', '');
    imgPixel.setAttribute('border', '0');
    imgPixel.setAttribute('width', '0');
    imgPixel.setAttribute('height', '0');
    imgPixel.src = src;
    document.body.appendChild(imgPixel);
  }

  private buildAffiliatePixel(tariffData: TariffData) {
    // no spaces allowed in communicationAdsLink
    const productname = (tariffData?.tariff)?.split(' ').join('_'); // es2021: replaceAll
    const affiliateCall = LinksMarketingPixelConfig.affiliate +
    'oid=' + this.trackingPixelData.orderId + '&' +
    'ocategory=' + productname + '&' +
    'ovalue=' + '1' + '&' +
    'oname=' + productname + '&' +
    's_id=' + this.trackingPixelData.s_id;

    this.buildPixel(affiliateCall);
  }

  private buildTelljaPixel(tariffData: TariffData) {
    const url = LinksMarketingPixelConfig.tellja;
    const src = url +
      'orderid=' + this.trackingPixelData.orderId + '&' +
      'telljaid=' + this.trackingPixelData.re_partnerid + '&' +
      't_articles=' + this.divisionPipe.transform(tariffData?.divisionId, false);
    this.buildPixel(src);
  }

}
