import { Injectable } from '@angular/core';
import { Environment } from '../../../environments/environment';
import { CUSTOMERMODE } from '../enums/customerMode';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';
import { STAGE } from '../enums/stage.enum';
import { TariffPriceAnnual } from '../../shared/models/tariff-price.annual';
import { TariffSummaryContractSums, TariffSummaryResults } from '../../../assets/js/com/ts_api_client';
import { TARIFF_TEXT_KEYS } from '../enums/tariffTextKeys';
import { TranslateService } from '@ngx-translate/core';
import { NetcolognePostcodes } from '../models/netcolognePostcodes';
import { COOPERATION_PARTNER_IDS } from '../enums/cooperationPartnerId.enum';

export interface ConsumptionStep {
  step: string;
  value: number;
}
const FLEX_ID_STAGING = 'RESXN0000000023';
const FLEX_ID_PRODUCTION = 'RESXN0000000036';
export const KEY_FOR_CHARGE_FLEX_BONUS = 'ChargeFlex Bonus';

const PRODUCTIDS_GENERAL_PRIV = [
  // Staging - PRIV
  'RESHN0001557531', // RE: Fair Ökostrom 12
  'REGHN0000099064', // RE: Fair Gas 12
  'RESHN0001557532', // RE: Fair Ökostrom 24
  'REGHN0000099065', // RE: Fair Gas 24
  'RESHN0001557600', // RE: EFFZEH-Strom
  'BKSHN0000739251', // BELKAW: Fair Ökostrom 24
  'BKSHN0000739288', // BELKAW: Fair Ökostrom 12 best
  'BKSHN0000739289', // BELKAW: Fair Ökostrom 24 best
  'BKGHN0000113838', // BELKAW: Fair Gas 24
  'BKGHN0000113860', // BELKAW: Fair Gas 12 best
  'BKGHN0000113861', // BELKAW: Fair Gas 24 best
  'SASHN0000000300', // SWA: Fair Ökostrom 24
  'SAGHN0000000157', // SWA: Fair Gas 24

  // Production - PRIV
  'RESHN0001557538', // RE: Fair Ökostrom 12
  'RESHN0001557539', // RE: Fair Ökostrom 24
  'REGHN0000099070', // RE: Fair Gas 12
  'REGHN0000099071', // RE: Fair Gas 24
  'BKSHN0000739251', // BELKAW: Fair Ökostrom 24
  'BKSHN0000739309', // BELKAW: Fair Ökostrom 12 best
  'BKSHN0000739308', // BELKAW: Fair Ökostrom 24 best
  'BKGHN0000113838', // BELKAW: Fair Gas 24
  'BKGHN0000113877', // BELKAW: Fair Gas 12 best
  'BKGHN0000113876', // BELKAW: Fair Gas 24 best
  'SASHN0000000300', // SWA: Fair Ökostrom 24
  'SAGHN0000000157', // SWA: Fair Gas 24
];

const PRODUCTIDS_GENERAL_GEWE = [
  // Staging - GEWE
  'REGGN0000047932', // RE-GEWE: Trade Gas 12
  'REGGN0000047928', // RE-GEWE: Trade Gas 24
  'RESGN0000463629', // RE-GEWE: Trade Ökostrom 12
  'RESGN0000463617', // RE-GEWE: Trade Ökostrom 24
  'BKGGN0000028425', // BELKAW-GEWE: Trade Gas 12
  'BKGGN0000028419', // BELKAW-GEWE: Trade Gas 24
  'BKSGN0000156418', // BELKAW-GEWE: Trade Ökostrom 12
  'BKSGN0000156424', // BELKAW-GEWE: Trade Ökostrom 24
  'SAGGN0000000035', // SWA-GEWE: Trade Gas 12
  'SAGGN0000000027', // SWA-GEWE: Trade Gas 24
  'SASGN0000000099', // SWA-GEWE: Trade Ökostrom 12
  'SASGN0000000095', // SWA-GEWE: Trade Ökostrom 24

  // Production - GEWE
  'REGGN0000047932', // RE-GEWE: Trade Gas 12
  'REGGN0000047928', // RE-GEWE: Trade Gas 24
  'RESGN0000463629', // RE-GEWE: Trade Ökostrom 12
  'RESGN0000463617', // RE-GEWE: Trade Ökostrom 24
  'BKGGN0000028425', // BELKAW-GEWE: Trade Gas 12
  'BKGGN0000028419', // BELKAW-GEWE: Trade Gas 24
  'BKSGN0000156418', // BELKAW-GEWE: Trade Ökostrom 12
  'BKSGN0000156401', // BELKAW-GEWE: Trade Ökostrom 24
  'SAGGN0000000035', // SWA-GEWE: Trade Gas 12
  'SAGGN0000000027', // SWA-GEWE: Trade Gas 24
  'SASGN0000000099', // SWA-GEWE: Trade Ökostrom 12
  'SASGN0000000095' // SWA-GEWE: Trade Ökostrom 24
];

const PRODUCTIDS_ADDITIONAL_CONTRACTNEW = [
];

const PRODUCTIDS_ADDITIONAL_MOVE = [
];

const PRODUCTIDS_BASESUPPLY_PRIV = [
  // Staging & Production
  'REGHG0000000043', // RE: Gas Grundversorgung
  'RESHG0000000510', // RE: Strom Grundversorung
  'BKGHG0000000036', // BELKAW: Gas Grundversorgung
  'BKSHG0000000339' // BELKAW: Strom Grundversorgung
];

const PRODUCTIDS_BASESUPPLY_GEWE = [
  // Staging & Production
  'REGGG0000000032', // RE: Trade Gas Grundversorgung
  'RESGG0000000263', // RE: Trade Strom Grundversorgung
];

const PRODUCTIDS_EFFZEH = [
  'RESHN0001557642', // Prod
  'RESHN0001557600' // Akz
];

@Injectable({
  providedIn: 'root'
})

export class TariffAdvisorService {
  constructor(
    private translateService: TranslateService
  ) { }

  /* availableTariffs are all tariffs from Tariff API with
     customerMode PRIV or GEWE
     process MOVE / CONTRACT_NEW -> currently there is no difference due to the process */
  public getNecessaryProductIds(process: string, isBaseSupplyArea: boolean, customerMode: CUSTOMERMODE): Array<string> {
    let productIds: Array<string>;
    // base supply and general tariffs for all processes and MVP products depending on process and customerMode
    const PRODUCTIDS_BASESUPPLY = customerMode === CUSTOMERMODE.PRIV ? PRODUCTIDS_BASESUPPLY_PRIV : PRODUCTIDS_BASESUPPLY_GEWE;
    const PRODUCTIDS_GENERAL = customerMode === CUSTOMERMODE.PRIV ? PRODUCTIDS_GENERAL_PRIV : PRODUCTIDS_GENERAL_GEWE;

    const baseSupplyProductIds = isBaseSupplyArea ? PRODUCTIDS_BASESUPPLY : [];
    productIds = baseSupplyProductIds.concat(PRODUCTIDS_GENERAL);
    switch (process) {
      case 'MOVE':
        productIds = productIds.concat(PRODUCTIDS_ADDITIONAL_MOVE);
        break;
      case 'CONTRACT_NEW':
      default:
        productIds = productIds.concat(PRODUCTIDS_ADDITIONAL_CONTRACTNEW);
        break;
    }
    return productIds;
  }

  /* CustomerMode PRIV or GEWE */
  public getMeterTooltip(customerMode: string) {
    const gross = customerMode === CUSTOMERMODE.PRIV;
    return `
    <div>
      <div class="re-mb-15">${this.translateService.instant('meterreadings.smartmeter.hintPrices')}</div>
      <table>
        <thead class="bold">
          <tr>
            <th colspan="2" class="re-pr-15">${this.translateService.instant('meterreadings.smartmeter.hintPricesConsumption')}</th>
            <th class="re-text-right">${this.translateService.instant('meterreadings.smartmeter.hintPricesCost')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td><span class="re-pr-10">–</span>10.000</td>
            <td class="re-text-right">${gross ? '20,00 €' : '16,81 €'}</td>
          </tr>
          <tr>
            <td>10.001</td>
            <td><span class="re-pr-10">–</span>20.000</td>
            <td class="re-text-right">${gross ? '50,00 €' : '42,02 €'}</td>
          </tr>
          <tr>
            <td>20.001</td>
            <td><span class="re-pr-10">–</span>50.000</td>
            <td class="re-text-right">${gross ? '90,00 €' : '75,63 €'}</td>
          </tr>
          <tr>
            <td>50.001</td>
            <td><span class="re-pr-10">–</span>100.000</td>
            <td class="re-text-right">${gross ? '120,00 €' : '100,84 €'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  `;
  }

  public getHTNTPrice(HTNT: 'HT' | 'NT', isGewe: boolean, contractSums: TariffSummaryContractSums[]): number {
    const contractSum = contractSums.find(
      (sum: TariffSummaryContractSums) =>
        (sum.level === 4 || sum.level === 11) && sum.description.trim() === (
          HTNT === 'HT'
            ? 'Hochtarif (HT)'
            : 'Niedertarif (NT)'
        )
    );

    return isGewe ? contractSum?.workNet : contractSum?.workGross;
  }

  public getPathForIcon(divisionId: string, mobileSize: boolean = false): string {
    let iconPath: string = '';

    switch (divisionId) {
      case VERBRAUCHSTYP.Strom:
        iconPath = mobileSize ? 'assets/icons/energy_30x30.svg' : 'assets/icons/energy_75x75.svg';
        break;
      case VERBRAUCHSTYP.Gas:
        iconPath = mobileSize ? 'assets/icons/gas_30x30.svg' : 'assets/icons/gas_75x75.svg';
        break;
      case VERBRAUCHSTYP.Wasser:
      case VERBRAUCHSTYP.Abwasser:
        iconPath = mobileSize ? 'assets/icons/water_30x30.svg' : 'assets/icons/water_75x75.svg';
        break;
      case VERBRAUCHSTYP.Fernwärme:
      case VERBRAUCHSTYP.Nahwärme:
        iconPath = mobileSize ? 'assets/icons/waerme_30x30.svg' : 'assets/icons/waerme_75x75.svg';
        break;
      default:
        iconPath = mobileSize ? 'assets/icons/energy_30x30.svg' : 'assets/icons/energy_75x75.svg';
        break;
    }
    return iconPath;
  }

  public getDocumentLink(): string {
    if (Environment.stage === STAGE.Production) {
      return this.translateService.instant('general.url.anchorCmsProd');
    } else {
      return this.translateService.instant('general.url.anchorCmsStaging');
    }
  }

  public getFlexTariffId(): string {
    if (Environment.stage === STAGE.Production) {
      return FLEX_ID_PRODUCTION;
    } else {
      return FLEX_ID_STAGING;
    }
  }

  public getPeriodicPricesByTimeSlices(tariffData: TariffSummaryResults): TariffPriceAnnual[] {
    const pricesByTimeSlice: TariffPriceAnnual[] = [];

    for (let index = 0; index < tariffData?.parts.length; index++) {
      pricesByTimeSlice.push(this.getPeriodicPrices(tariffData, index));
    }
    // Remove null values just in case
    return pricesByTimeSlice?.filter(Boolean);
  }

  public getPeriodicPrices(tariffData: TariffSummaryResults, index: number = 0): TariffPriceAnnual {
    if (!tariffData?.parts?.[index]?.periodicPrices) return null;
    const firstYear = tariffData?.parts?.[index]?.periodicPrices.find((item) => item.year === 1);
    const secondYear = tariffData?.parts?.[index]?.periodicPrices.find((item) => item.year === 2);
    let prices: TariffPriceAnnual;

    if (tariffData?.customerType === CUSTOMERMODE.PRIV) {
      prices = {
        priceStartDate: tariffData?.parts?.[index]?.contractStartDate,
        priceEndDate: tariffData?.parts?.[index]?.contractEndDate,
        firstYear: {
          year: firstYear.year,
          annualPrice: firstYear.annualPriceGross,
          monthlyPrice: firstYear.monthlyPriceGross
        },
        secondYear: {
          year: secondYear ? secondYear.year : null,
          annualPrice: secondYear ? secondYear.annualPriceGross : null,
          monthlyPrice: secondYear ? secondYear.monthlyPriceGross : null
        },
        priceCapYear: {
          annualPrice: tariffData?.parts?.[index]?.priceCap?.annualPriceGross,
          monthlyPrice: tariffData?.parts?.[index]?.priceCap?.monthlyPriceFirstYearGross
        }
      };
    } else {
      prices = {
        priceStartDate: tariffData?.parts?.[index]?.contractStartDate,
        priceEndDate: tariffData?.parts?.[index]?.contractEndDate,
        firstYear: {
          year: firstYear ? firstYear.year : null,
          annualPrice: firstYear ? firstYear.annualPriceNet : null,
          monthlyPrice: firstYear ? firstYear.monthlyPriceNet : null
        },
        secondYear: {
          year: secondYear ? secondYear.year : null,
          annualPrice: secondYear ? secondYear.annualPriceNet : null,
          monthlyPrice: secondYear ? secondYear.monthlyPriceNet : null
        },
        priceCapYear: {
          annualPrice: tariffData?.parts?.[index]?.priceCap?.annualPriceNet,
          monthlyPrice: tariffData?.parts?.[index]?.priceCap?.monthlyPriceFirstYearNet
        }
      };
    }
    return prices;
  }

  getKeyForTariffInfo(customerType: CUSTOMERMODE, divisionId: VERBRAUCHSTYP): string {
    let key: string = '';

    if (customerType === CUSTOMERMODE.GEWE) {
      if (divisionId === VERBRAUCHSTYP.Strom) {
        key = TARIFF_TEXT_KEYS.PriceInfo_tradeCustomer_S4;
      } else if (divisionId === VERBRAUCHSTYP.Gas) {
        key = TARIFF_TEXT_KEYS.PriceInfo_tradeCustomer_G6;
      }
    } else if (customerType === CUSTOMERMODE.PRIV) {
      if (divisionId === VERBRAUCHSTYP.Strom) {
        key = TARIFF_TEXT_KEYS.PriceInfo_privateCustomer_S4;
      } else if (divisionId === VERBRAUCHSTYP.Gas) {
        key = TARIFF_TEXT_KEYS.PriceInfo_privateCustomer_G6;
      }
    }
    return key;
  }


  getConsumptionSteps(divisionId: string): Array<ConsumptionStep> {
    switch (divisionId) {
      case VERBRAUCHSTYP.Strom:
        return [
          { step: '1', value: 1500 },
          { step: '2', value: 2500 },
          { step: '3', value: 3500 },
          { step: '4', value: 4250 },
          { step: '5', value: 5500 },
          { step: '6', value: 6500 },
        ];
      case VERBRAUCHSTYP.Gas:
        return [
          { step: '50', value: 5000 },
          { step: '100', value: 12000 },
          { step: '150', value: 18000 },
          { step: '180', value: 20000 },
          { step: '250', value: 35000 },
        ];
      default:
        return [];
    }
  }

  getStepByConsumption(divisionId: string, value: string | number): string | undefined {
    const consumptionAsNumber = typeof value === 'number' ? value : parseInt(value, 10);
    return this.getConsumptionSteps(divisionId)?.find((step) => step.value === consumptionAsNumber)?.step ?? undefined;
  }

  isEffzeh(productIds: Array<string | undefined>) {
    const set = new Set(PRODUCTIDS_EFFZEH);
    return productIds?.some(item => set.has(item));
  }

  getCooperationPartnerId(postCode: string, productIds: Array<string>): string {
    if (this.isEffzeh(productIds)) {
      return COOPERATION_PARTNER_IDS.EFFZEHSTROM;
    } else if (NetcolognePostcodes.includes(postCode)) {
      return COOPERATION_PARTNER_IDS.NETCOLOGNE;
    }
    return '';
  }
}
