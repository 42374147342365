import { EventEmitter, Injectable } from '@angular/core';
import { from, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import {
  ActivationData,
  AuthService,
  CheckIdentificationData,
  CheckUsernameResponse,
  RegistrationData,
  StatusResponse,
} from '../../assets/js/com/ts_api_client';
import { ChangePasswordData } from '../bdo/models/changePasswordData';
import { Environment } from '../../environments/environment';
import { TENANT_ACRONYM } from '../bdo/enums/tenant.enum';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import {
  ResetPasswordOutput,
  confirmResetPassword,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  updatePassword } from 'aws-amplify/auth';
import { DebugLogger } from '../shared/utils/debugLogger';
import { Credentials } from './login-form/login-form.component';
import { CustomerStoreService } from '../bdo/services/customer-store.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { RenewPasswordData } from '../bdo/models/renewPasswordData';
import moment from 'moment';
import { StorageService } from '../bdo/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public loginStatusEvent: EventEmitter<boolean>;
  public tenant: string;
  public isLoggedIn$: Observable<boolean>;
  public onLogout$: Observable<boolean>;
  public tosAccepted$: Subject<boolean> = new Subject();
  public tosPending = false;
  public configured$ = new ReplaySubject(1);

  constructor(
    private authService: AuthService,
    private router: Router,
    private customerStore: CustomerStoreService,
    private location: Location
  ) {

    this.tenant = TENANT_ACRONYM[Environment.tenant];
    this.loginStatusEvent = new EventEmitter<boolean>();
    // https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/#api-reference
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'tokenRefresh_failure':
        case 'signedIn':
          StorageService.setPollShown(false);
          break;
        case 'signedOut':
          this.loginStatusEvent.emit(false);
          break;
      }
    });

    this.configured$.next(true);
    this.isLoggedIn().subscribe();

    // update the global application loginState once cognito has been configured
    this.isLoggedIn$ = this.loginStatusEvent.pipe(distinctUntilChanged(), shareReplay(1));
    this.onLogout$ = this.isLoggedIn$.pipe(distinctUntilChanged(), filter((isLoggedIn) => !isLoggedIn));
  }

  /**
   * login process by login.component
   * @param credentials
   * @param destination
   */
  public login(credentials: Credentials, destination: string) {
    const username = credentials.username.trimEnd();
    const password = credentials.password;
    return from(signIn({ username, password, options: {
      'API_TAGS': Environment.apiTags,
      'branch': Environment.branch,
      authFlowType: 'USER_PASSWORD_AUTH' }
    })).pipe(tap((loggedIn) => {
      if (loggedIn) {
        this.isLoggedIn().subscribe();
      }
    }));
  }

  /**
   * check if token is valid and set state
   */
  public isLoggedIn(): Observable<boolean> {
    return from(fetchAuthSession()).pipe(
      map((session) => {
        return !!session.tokens;
      }),
      /*
      Catch error as loggedIn=false since no active session is treated as error in the aws sdk but we need true or false
       */
      catchError((error: unknown) => {
        DebugLogger.error(this.constructor.name, 'isLoggedIn()', error);
        return of(false);
      }),
      tap({ next: (result) => this.loginStatusEvent.emit(result) })
    );
  }

  /* Checking Verified Status of Email-Address */
  public isVerifiedEmail(): Observable<boolean> {
    return from(
      fetchUserAttributes().then(userAttributes => {
        return userAttributes?.email_verified === 'true';
      })
    );
  }

  /* Checking if we have an unverified email within the time limit (24 hours) */
  public getTempUnverifiedEmail(): Observable<string> {
    fetchAuthSession({ forceRefresh: true });
    return from(
      fetchUserAttributes().then(userAttributes => {
        const tmpEmailChangedDate = userAttributes?.['custom:tmpEmailChangedDate'];
        if (!tmpEmailChangedDate) {
          return '';
        }
        const isStillValid = moment().diff(moment(tmpEmailChangedDate), 'hours') < 24;
        if (isStillValid){
          return userAttributes?.['custom:tmpEmail'];
        } else return '';
      })
    );
  }

  public getEmail(): Observable<string> {
    return from(
      fetchUserAttributes().then(userAttributes => {
        return userAttributes?.email;
      })
    );
  }

  /**
   *
   * @param deeplink optional target needed if the AuthGuard stopped navigation and we want to go there instead of the current location
   */
  redirectToLoginPage(deeplink?: string) {
    const pathsWithNoRedirectToLogin = ['/login', '/logout', '/benutzername_vergessen', '/passwort_vergessen',
      '/zaehlerstandeingabe', '/zaehlerstand/erfassen', '/registrieren', 'kuendigen', '/passwort-erneuern', '/verifizieren'
    ];
    const path = deeplink || '/' + this.location.path() || '';

    let targetPath = '/login';
    if (path.includes('umzug')) {
      targetPath = '/umzug/auth';
    } else if (path.includes('vertrag') && path.includes('anpassen')) {
      targetPath = '/vertrag/auth';
    }

    // Redirection to Loginpage if not logged in
    if (!pathsWithNoRedirectToLogin.find((item) => path.indexOf(item) !== -1) && !path.includes('vertrag-abschliessen/neu') && path !== targetPath) {

      // set deeplink for redirection after successful login
      this.customerStore.setDeepLink(path);

      this.router.navigate([targetPath], { queryParamsHandling: 'merge' });
    }
  }

  logout(): Observable<any> {
    return of(signOut()).pipe(tap({ next: val => {
      DebugLogger.debug(this.constructor.name, 'logout()');
      this.loginStatusEvent.emit(false);
    } }));
  }

  public getUsername(): Observable<string> {
    return from(getCurrentUser()).pipe(
      map((userInfo) => userInfo.signInDetails.loginId),
      catchError((error: unknown) => {
        DebugLogger.error(this.constructor.name, 'getUsername()', error);
        return of('');
      })
    );
  }

  renewPassword(data: RenewPasswordData): Observable<any> {
    return from(confirmResetPassword({
      username: data.username.trim(),
      confirmationCode: data.code,
      newPassword: data.newPassword,
      options:
        { 'API_TAGS': Environment.apiTags, 'branch': Environment.branch }
    }));
  }

  resetPassword(name: string): Observable<ResetPasswordOutput> {
    return from(resetPassword({ username: name.trim(), options: { 'API_TAGS': Environment.apiTags, 'branch': Environment.branch } }));
  }

  changePassword(passwordData: ChangePasswordData): Observable<void> {
    return from(updatePassword({
      oldPassword: passwordData.currentPassword,
      newPassword: passwordData.newPassword
    }));
  };

  checkIdentification(accountId: string, meterNumberOrRegisterCode: string, date?: Date): Observable<StatusResponse> {
    const identificationData: CheckIdentificationData = { 'accountId': accountId, 'meterNumberOrRegisterCode': meterNumberOrRegisterCode, 'atDate': date };
    return this.authService.postCheckIdentification(this.tenant, identificationData);
  }

  checkUsernameAvailable(username: string): Observable<CheckUsernameResponse> {
    return this.authService.getCheckUsername(this.tenant, username);
  }

  checkIdentificationSalesforce(accountId: string, meterNumberOrRegisterCode: string, date?: Date): Observable<StatusResponse> {
    const identificationData: CheckIdentificationData = { 'accountId': accountId, 'meterNumberOrRegisterCode': meterNumberOrRegisterCode, 'atDate': date };
    return (this.authService as any).postCheckIdentificationSalesforce(this.tenant, identificationData);
  }

  checkSalesforceUsernameAvailable(username: string): Observable<CheckUsernameResponse> {
    return this.authService.getCheckUsernameSalesforce(this.tenant, username);
  }

  register(registrationData: RegistrationData): Observable<StatusResponse> {
    return this.authService.postRegistration(this.tenant, registrationData);
  }

  activate(code: string, token: string, registerCode: string | null): Observable<StatusResponse> {
    const activationData: ActivationData = { code: code, token: token, registerCode: registerCode || '' };
    return this.authService.postActivate(this.tenant, activationData);
  }

  getToken(): Observable<string>{
    return this.configured$.pipe(
      filter(configured => !!configured),
      first(),
      switchMap(() => {
        return from(fetchAuthSession()).pipe(map(result => result.tokens.idToken.toString()));
      }),
      catchError((error: unknown) => {
        this.logout().subscribe({
          next: () => this.redirectToLoginPage()
        });
        // rethrow error to end observable chain, so no http call will be made
        return throwError(() => error);
      })
    );
  }

  configure() {
    Amplify.configure(Environment.awsAuthConfig);
    this.isLoggedIn().subscribe();
  }
}
