import { Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subscription } from 'rxjs';
import { ConsumptionFormtype } from '../../../../../shared/formtypes/consumption.formtype';
import { defaultValidatorProxy } from '../../../../../shared/validators/default-validator-proxy';
import { INPUT_TYPE } from '../../../../enums/inputType.enum';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'bdo-charging-consumption',
  templateUrl: './charging-consumption.component.html',
  styleUrl: './charging-consumption.component.scss'
})
export class ChargingConsumptionComponent implements OnInit, OnDestroy {
  @Input() parentForm = new FormGroup<ConsumptionFormtype>({});
  public consumptionKnown: WritableSignal<boolean | undefined> = signal(undefined);
  public INPUT_TYPE = INPUT_TYPE;
  public calculatedConsumption: number = 0;
  private manualChargingFormSubscription: Subscription = new Subscription();

  public constructor(
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.parentForm.addControl(
      'chargingConsumption',
       new FormControl<number>(null,
        [
          defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          defaultValidatorProxy(Validators.pattern(/^\d*$/), this.translateService.instant('general.error.wrongNumberFormat'))
        ]
      ),
    );

    const chargeFlexData = StorageService.getChargeFlexData();
    if (chargeFlexData?.chargingConsumption) {
      this.parentForm.controls.chargingConsumption.setValue(chargeFlexData?.chargingConsumption);
    }

this.consumptionKnown.set(!!chargeFlexData?.consumptionKnown);

    if (chargeFlexData?.consumptionKnown !== true) {
      this.updateChargingForm();
      this.parentForm.controls.kmPerYear.setValue(StorageService.getChargeFlexData()?.kmPerYear);
      this.parentForm.controls.consumptionPer100.setValue(StorageService.getChargeFlexData()?.consumptionPer100);
      this.parentForm.controls.homeChargingPercentage.setValue(StorageService.getChargeFlexData()?.homeChargingPercentage);
    }


  }

  public ngOnDestroy(): void {
    this.manualChargingFormSubscription.unsubscribe();
    this.parentForm.removeControl('chargingConsumption');
  }

  updateChargingForm() {
    if (this.consumptionKnown()) {
      this.parentForm.removeControl('kmPerYear');
      this.parentForm.removeControl('consumptionPer100');
      this.parentForm.removeControl('homeChargingPercentage');
      this.manualChargingFormSubscription.unsubscribe();
      this.calculatedConsumption = 0;
    } else {
      this.parentForm.addControl(
        'kmPerYear',
        new FormControl<number>(null,
          [
            defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          ]
        ),
      );
      this.parentForm.addControl(
        'consumptionPer100',
        new FormControl<number>(null,
          [
            defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          ]
        ),
      );
      this.parentForm.addControl(
        'homeChargingPercentage',
        new FormControl<number>(null,
          [
            defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
            defaultValidatorProxy(Validators.max(100), this.translateService.instant('general.validator.max', { value: 100 })),
          ]
        )
      );

      // Reinitialize the subscription otherwise it will no longer react to a second toggle with its status "closed"
      if (this.manualChargingFormSubscription.closed) {
        this.manualChargingFormSubscription = new Subscription();
      }
      this.manualChargingFormSubscription.add(combineLatest([
        this.parentForm.controls.kmPerYear.valueChanges,
        this.parentForm.controls.consumptionPer100.valueChanges,
        this.parentForm.controls.homeChargingPercentage.valueChanges,
      ]).subscribe(() => {
        this.updateCalculatedConsumption();
      }));
    }
  }

  onToggleConsumptionKnown() {
    this.consumptionKnown.update(value => !value);
    StorageService.updateChargeFlexData({
      consumptionKnown: this.consumptionKnown()
    });

    this.updateChargingForm();
  }

  updateCalculatedConsumption(): void {
    this.calculatedConsumption =
      Math.round(this.parentForm.controls.kmPerYear.value *
      this.parentForm.controls.consumptionPer100.value / 100 *
      this.parentForm.controls.homeChargingPercentage.value / 100);
    this.parentForm.controls.chargingConsumption.setValue(this.calculatedConsumption);
  }

}
