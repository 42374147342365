import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { LOADING_STATE } from '../../../bdo/enums/loadingState.enum';
import { TenantService } from '../../../bdo/services/tenant.service';
import { InputComponent } from '../input/input.component';
import { AutosuggestItem } from './models/autosuggest-item.model';
import * as stringSimilarity from 'string-similarity';
enum KEY_CODE {
  TOP_ARROW = 38,
  DOWN_ARROW = 40,
  ENTER = 13,
}

@Component({
  selector: 'bdo-autosuggest',
  templateUrl: './autosuggest.component.html',
  styleUrls: ['./autosuggest.component.scss']
})
export class AutosuggestComponent implements OnInit, OnDestroy {

  @ViewChildren('items') listItems: QueryList<ElementRef>;

  @Input() dataSource$: Observable<string[]> = of([]);
  @Input() showOffset: number = 1;
  @Input() searchString$: Observable<string>;
  @Input() show: boolean = false;
  @Input() for: InputComponent;
  @Input() sort: (a, b) => 1 | -1 | 0;
  @Input() extraOptionAlwaysVisible: string ;
  @Output() itemSelected = new EventEmitter<string>();
  @Output() noItemsAvailable = new EventEmitter<boolean>();

  public items: AutosuggestItem[];
  public LoadingState = LOADING_STATE;
  public focused = false;
  public inputFocused = false;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public selectedExtra: boolean = false;
  public maxElements = 5;
  private subscriptions = new Subscription();
  private selectedIndex: number = null;
  private swipe: boolean = false;

  constructor(private eRef: ElementRef, public tenantService: TenantService) { }


  @HostListener('document:mouseDown', ['$event'])
  catchClicks(event) {
    if (this.show && this.focused) {
      if (!this.eRef.nativeElement.contains(event.target)) {
        this.focused = this.inputFocused;
      }
      if (this.for?.eRef.nativeElement.contains(event.target)  ) {
        this.focused = true;
      }
    }
  }
  @HostListener('document:touchEnd', ['$event'])
  catchTaps(event) {
    if (this.show && this.focused) {
      if (!this.eRef.nativeElement.contains(event.target)) {
        this.focused = this.inputFocused;
      }
      if (this.for?.eRef.nativeElement.contains(event.target)  ) {
        this.focused = true;
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  catchArrowKeys(event) {
    if (this.show && this.focused) { // Needed to be able to use multiple instances!
      if (event.keyCode === KEY_CODE.TOP_ARROW) {
        event.preventDefault();
        if (this.selectedIndex === null) {
          // select last item of list
          this.selectedIndex = this.items?.length - 1;
          this.selectedExtra = false;
        } else if (this.selectedIndex === 0) {
          // do not change selection, if the bottom of the list has been reached
          return;
        } else {
          // select element above the current one
          this.selectedIndex = this.selectedIndex - 1;
        }
        this.markAsSelected(this.selectedIndex);
      }
      if (event.keyCode === KEY_CODE.DOWN_ARROW) {
        event.preventDefault();
        if (this.selectedIndex === null) {
          // select the first element of the list
          this.selectedIndex = 0;
        } else if (this.selectedIndex === this.items?.length - 1 && this.extraOptionAlwaysVisible?.length) {
          this.selectedExtra = true;
          this.markAsUnselected();
          return;
        } else if (this.selectedIndex === this.items?.length - 1) {
          // do not change selection, if the end of the list has been reached
          return;
        } else {
          // select the element below the current one
          this.selectedIndex = this.selectedIndex + 1;
        }
        this.markAsSelected(this.selectedIndex);
      }

      // use selected entry in autosuggest, if enter is pressed
      if (event.keyCode === KEY_CODE.ENTER) {
        if (this.selectedIndex !== null) {
          this.itemSelected.emit(this.items[this.selectedIndex].original);
          this.selectedIndex = null;

          // blur active input element to hide suggestion list
          (document.activeElement as any).blur();
          this.focused = false;

          // prevent default behaviour of enter
          event.preventDefault();
          return false;
        } else if (this.selectedExtra) {
          this.itemSelected.emit(this.extraOptionAlwaysVisible);

          // blur active input element to hide suggestion list
          (document.activeElement as HTMLElement).blur();
          this.focused = false;
        }
      }
    }
  }

  public ngOnInit(): void {

    /*
     make sure this autosuggest disappears if the parent input loses focus
     */
    this.subscriptions.add(this.for.blur.subscribe({ next: () => {
      if (this.selectedIndex !== null) {
        this.itemSelected.emit(this.items[this.selectedIndex].original);
        this.selectedIndex = null;
      }
      this.focused = false;
    } }));

    this.for.eRef.nativeElement.addEventListener('keydown', (e) => {
      if ([ KEY_CODE.TOP_ARROW, KEY_CODE.DOWN_ARROW].includes(e.keyCode)) {
        e.preventDefault();
      }
    }, false);
    this.for.eRef.nativeElement.addEventListener('keyup', (e) => {
      if ([ KEY_CODE.TOP_ARROW, KEY_CODE.DOWN_ARROW].includes(e.keyCode)) {
        e.preventDefault();
      }
    }, false);
    this.hideForTooShortStrings();
    const conditionalSearchString$ = this.showOffset === -1 ? this.searchString$.pipe(
      startWith<string>('')) : this.searchString$;
    this.subscriptions.add(conditionalSearchString$.pipe(
        tap({ next: () => this.state = LOADING_STATE.LOADING }),
        filter((searchString) => searchString?.length > this.showOffset),
        tap({ next: () => this.show = true }),
        tap({ next: () => {
          this.selectedIndex = null;
        } }),
        switchMap((searchString) => {
          return this.dataSource$.pipe(
            map((items) => this.createAutoSuggestItems(items, searchString)),
            // do not show suggestion if input matches the only item, that has been found
            tap({ next: (items) => {
              if (items.length === 1 && items[0].original === searchString) {
                this.show = false;
              }
            } }),
          );
        }),
      tap({ next: (result) => {
        this.state = result.length ? LOADING_STATE.IDLE : LOADING_STATE.ERROR;
        this.items = result;

      } }),
      tap({ next: (result) => {
        if (this.state === LOADING_STATE.ERROR) {
          this.noItemsAvailable.emit(true);
        } else {
          this.noItemsAvailable.emit(false);
        }
      } })
    ).subscribe());
    this.subscriptions.add(this.for.focus.subscribe({ next: () => {
      this.inputFocused = true;
      this.focused = true;
    } }));
    this.subscriptions.add(this.for.blur.subscribe({ next: () => this.inputFocused = false }));
  }

  public onClick(index: number) {
    // if swipe was the previous event, do not trigger the click
    if (this.swipe) {
      this.swipe = false;
      return;
    }
    this.itemSelected.emit(this.items[index].original);
    this.selectedIndex = null;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * dynamic caluclation of the height, based on the number of items. If the max height of the list is reached,
   * the next item in the list is displayed by half (to give the user a hint, that there is a scrollable list)
   * @param {AutosuggestItem[]} items
   * @returns {string}
   */
  public calculateHeight(items: AutosuggestItem[]): string {
    const heightOfOneElement = 50;


    if (items.length === 0) {
      return heightOfOneElement + 'px'; // place for "Keine Ergebnisse gefunden"
    } else if (items.length <= this.maxElements) {
      return heightOfOneElement * (items.length + (this.extraOptionAlwaysVisible?.length ? 1 : 0)) + 'px';
    } else {
      // if more than maxElements, show the half of the next element to give the user a hint, that the list is scrollable
      return (heightOfOneElement * this.maxElements + heightOfOneElement / 2) + 'px';
    }
  }

  public markAsSelected(i: number) {
    this.selectedIndex = i;
    this.selectedExtra = false;
    let oldIndex = this.items.findIndex(item => item.selected === true) ;
    // return new reference to object with current selection state to let angular rerender this particular item
    this.items = this.items?.map((item, index) => {
      return index === i && !item.selected ? {
        ...item,
        selected: true
      } : index !== i && item.selected ? {
        ...item,
        selected: false
      } : item;
    });

    // With extra option directly scroll so new option does not get hidden by it
    if (this.extraOptionAlwaysVisible?.length && i > oldIndex && i+1 >= this.maxElements) {
      this.listItems.toArray()[i].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    } else if (this.listItems?.toArray()[i]) {
      this.listItems.toArray()[i].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  public markAsUnselected() {
    if (this.selectedIndex !== null) {
      this.items[this.selectedIndex].selected = false;
      this.selectedIndex = null;
    }
  }

  onSwipe($event: number) {
    this.swipe = true;
  }

  private createAutoSuggestItems(items: string[], searchstring: string): AutosuggestItem[] {
    const searchStringMatchesItem = items.find((item) => item === searchstring);
    if (!!searchStringMatchesItem) {
      return [{
        before: searchStringMatchesItem,
        after: '',
        searchstring: '',
        index: -1,
        selected: false,
        original: searchStringMatchesItem
      }];
    }
    const result = items
      .filter(this.search(searchstring))
      .map((item) => {
        const index = item.toLowerCase().indexOf(searchstring.toLowerCase());
        // split autosuggest into highlighted part and before and after parts

        // do not highlight for similar matches
        if (index !== -1) {
          return {
            before: item.substr(0, index).replace(/\s/g, '&nbsp;'),
            after: item.substr(index + searchstring.length).replace(/\s/g, '&nbsp;'),
            searchstring: item.substr(index,  searchstring.length).replace(/\s/g, '&nbsp;'),
            selected: false,
            index,
            original: item
          };
        } else {
          return {
            before: item,
            after: '',
            searchstring: '',
            index,
            selected: false,
            original: item
          };
        }

      });

    const split = {
      startingWith: result.filter((item) => item.index === 0).sort(),
      others: result.filter((item) => item.index !== 0).sort()
    };
    return [...split.startingWith, ...split.others];

  }


  private search(searchstring: string) {
    return (item) => {
      const similarity = stringSimilarity.compareTwoStrings(searchstring.toLowerCase(), item.toLowerCase());
      return similarity >= 0.6 || item.toLowerCase().indexOf(searchstring.toLowerCase()) !== -1;
    };
  }

  private hideForTooShortStrings() {
    this.subscriptions.add(this.searchString$
      .pipe(
        filter(value => value?.length <= this.showOffset),
        tap({ next: () => this.show = false })
      ).subscribe());
  }
}
