<div>
  <fieldset>
    <div class="re-form-item-container">
      <div class="bold">{{'tmh.consumption.headingCalculate' | translate}}</div>
      <bdo-toggle-button
        [description]="(consumptionKnown() ? 'tmh.consumption.calculate' : 'tmh.consumption.type') | translate"
        [enabled]="consumptionKnown()"
        [labelRight]="'tmh.chargingConsumption.toggleConsumptionKnown' | translate"
        (toggle)="onToggleConsumptionKnown()"
      ></bdo-toggle-button>

      <div class="re-mt-20" *ngIf="consumptionKnown(); else fullForm">
        <bdo-input [formControl]="parentForm.controls.chargingConsumption"
          ngDefaultControl
          [inputType]="INPUT_TYPE.NUMBER"
          [labelText]="'tmh.chargingConsumption.yearlyCharge' | translate"
          [id]="'chargingConsumption'">
        </bdo-input>
      </div>
      <ng-template #fullForm>
        <div class="re-flex re-flex-col re-gap-20">
          <bdo-input [formControl]="parentForm.controls.kmPerYear"
            ngDefaultControl
            [inputType]="INPUT_TYPE.NUMBER"
            [labelText]="'tmh.chargingConsumption.kmPerYear' | translate"
            [id]="'chargingConsumption'">
          </bdo-input>
          <bdo-input [formControl]="parentForm.controls.consumptionPer100"
            ngDefaultControl
            [inputType]="INPUT_TYPE.NUMBER"
            [labelText]="'tmh.chargingConsumption.consumptionPer100' | translate"
            [id]="'chargingConsumption'"
            (blur)="null">
          </bdo-input>
          <bdo-input [formControl]="parentForm.controls.homeChargingPercentage"
            ngDefaultControl
            [inputType]="INPUT_TYPE.NUMBER"
            [labelText]="'tmh.chargingConsumption.homeChargingPercentage' | translate"
            [id]="'chargingConsumption'"
            (blur)="null">
          </bdo-input>

          <div *ngIf="true"
            class="re-mt-20"
            [innerHTML]="'tmh.chargingConsumption.calculatedConsumption' | translate: {consumption: calculatedConsumption || '___'}"
          ></div>
        </div>
      </ng-template>
    </div>

  </fieldset>
</div>
