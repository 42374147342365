/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TariffSummaryContractPayments { 
    description?: string;
    baseGross?: number;
    baseNet?: number;
    onetime?: boolean;
    payment?: string;
    quantity?: number;
    quantityUnit?: string;
    unitPriceGross?: number;
    unitPriceNet?: number;
    unitPriceCurrency?: string;
}

